import * as Styled from "./styles";
import Container from "../../layout/container/Container";
import Button from "../../ui/button/Button";

import garantii_i_skidki from "../../../assets/documents/Гарантии_и_скидки.pdf";
import controlling_organizations from "../../../assets/documents/Контролирующие_организации.pdf";
import medics_help_in_systeme_dms from "../../../assets/documents/Медицинская_помощь_в_системе_ДМС.pdf";
import medics_help_in_systeme_oms from "../../../assets/documents/Медицинская_помощь_в_системе_ОМС.docx";
import postanovlenie_661 from "../../../assets/documents/Постановление_661_от_30_декабря 2020г_о_Территориальной_программе_государственных_гарантий_бесплатного_оказания_гражданам_медицинской_помощи_на_территории_УР_на_2021_и_на_плановый_2022_и_2023_годов.pdf";
import rights_and_obligations_of_patients from "../../../assets/documents/Права_и_обязанности_пациентов.pdf";
import rules_of_the_internal_order from "../../../assets/documents/Правила_внутреннего_распорядка.pdf";
import pricelist_services from "../../../assets/documents/Прейскурант_услуг.pdf";
import recommendations_to_patients from "../../../assets/documents/Рекомендации_пациентам.pdf";
import summary_results from "../../../assets/documents/Сводная_ведомость_результатов_специальной_оценки_условий_труда.pdf";
// import SvgIcon from "../../ui/svg-icon/SvgIcon";

const documents = [
  {
    id: 1,
    name: "Гарантии и скидки",
    src: garantii_i_skidki,
    size: "193 КБ",
    type: "pdf",
  },
  {
    id: 2,
    name: "Контролирующие организации",
    src: controlling_organizations,
    size: "449 КБ",
    type: "pdf",
  },
  {
    id: 3,
    name: "Медицинская помощь в системе ДМС",
    src: medics_help_in_systeme_dms,
    size: "430 КБ",
    type: "pdf",
  },
  {
    id: 4,
    name: "Медицинская помощь в системе ОМС",
    src: medics_help_in_systeme_oms,
    size: "32 КБ",
    type: "docx",
  },
  {
    id: 5,
    name: "Постановление  №661 от 30 декабря 2020г о Территориальной программе  государственных гарантий бесплатного оказания гражданам медицинской помощи на территории УР  на 2021 и на плановый 2022 и 2023 годов",
    src: postanovlenie_661,
    size: "14.65 МБ",
    type: "pdf",
  },
  {
    id: 6,
    name: "Права и обязанности пациентов",
    src: rights_and_obligations_of_patients,
    size: "316 КБ",
    type: "pdf",
  },
  {
    id: 7,
    name: "Правила внутреннего распорядка",
    src: rules_of_the_internal_order,
    size: "397 КБ",
    type: "pdf",
  },
  {
    id: 8,
    name: "Прейскурант услуг",
    src: pricelist_services,
    size: "236 КБ",
    type: "pdf",
  },
  {
    id: 9,
    name: "Рекомендации пациентам",
    src: recommendations_to_patients,
    size: "623 КБ",
    type: "pdf",
  },
  {
    id: 10,
    name: "Сводная ведомость результатов специальной оценки условий труда",
    src: summary_results,
    size: "2.42 МБ",
    type: "pdf",
  },
];

const Documents = () => {
  return (
    <Container>
      <Styled.Documents>
        <Styled.DocumentTitle>Документы для ознакомления</Styled.DocumentTitle>
        <Styled.DocumentsList>
          {documents.map((document) => (
            <Styled.Document key={document.id}>
              <Styled.DocumentName>
                <Styled.DocumentNameType>
                  {document.type}
                </Styled.DocumentNameType>
                <Styled.DocumentNameText>
                  {document.name}
                </Styled.DocumentNameText>
              </Styled.DocumentName>
              <Styled.DocumentFooter>
                <Styled.DocumentSize>
                  Размер: {document.size}
                </Styled.DocumentSize>
                <Styled.DocumentButtons>
                  <Button
                    link={document.src}
                    download
                    className="link"
                    size="sm"
                    svgName="icon-dowload-outline"
                  >
                    Скачать
                  </Button>
                  {document.type === "pdf" && (
                    <Button
                      link={document.src}
                      target="_blank"
                      className="outline"
                      size="sm"
                      svgName="icon-document-ouline"
                    >
                      Открыть
                    </Button>
                  )}
                </Styled.DocumentButtons>
              </Styled.DocumentFooter>
            </Styled.Document>
          ))}
        </Styled.DocumentsList>
      </Styled.Documents>
    </Container>
  );
};

export default Documents;
