import styled from "styled-components";
import { device } from "../../../thems/breakpoint";

export const Documents = styled.div``;

export const DocumentTitle = styled.h2`
  margin: 0;
  font-size: 32px;
  line-height: 1.2;
  font-weight: 600;
  color: ${(props) => props.theme.textColorOpacity90};

  @media ${device.mobileL} {
    font-size: 40px;
  }

  @media ${device.tablet} {
    font-size: 48px;
  }
`;

export const DocumentsList = styled.div`
  margin-top: 40px;

  @media ${device.tablet} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;
  }
`;

export const Document = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.9);
  background-color: #242d33;
  transition: color 0.15s linear;

  & + & {
    margin-top: 16px;
  }

  @media ${device.tablet} {
    & + & {
      margin-top: 0;
    }
  }
`;

export const DocumentName = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 12px;
`;

export const DocumentNameType = styled.div`
  padding: 4px 6px;
  transform: translateY(3px);
  border: 1px solid #ea6165;
  border-radius: 4px;
  font-size: 8px;
  font-weight: 600;
  line-height: 1;
  color: #ea6165;
  text-transform: uppercase;
`;

export const DocumentNameText = styled.p`
  margin: 0;
`;

export const DocumentFooter = styled.footer`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  gap: 12px 16px;
  margin-top: auto;
`;

export const DocumentSize = styled.p`
  margin: 0;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
`;

export const DocumentButtons = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
`;
