import Container from "../../../layout/container/Container";
import * as Styled from "./styles";
import { dataSpecialists } from "../../../../mock/specialists";
import SpecialistCard from "../specialist-card/SpecialistCard";
import Modal from "../../../ui/modal/Modal";
import { Fragment, useState } from "react";
import SpecialistModal from "../specialist-modal/SpecialistModal";

const SpecialistsGrid = () => {
  const [showModal, setShowModal] = useState(false);
  const [activeSpecialistId, setActiveSpecialistId] = useState(1);

  return (
    <Container>
      <Styled.SpecialistsGrid>
        <Styled.SpecialistsGridTitle>Специалисты</Styled.SpecialistsGridTitle>
        <Styled.SpecialistsGridList>
          {dataSpecialists.map((specialist) => (
            <SpecialistCard
              key={specialist.id}
              id={specialist.id}
              name={specialist.name}
              position={specialist.position}
              education={specialist.education}
              image={specialist.image}
              className="second"
              onShowModal={setShowModal}
              onShowSpecialist={setActiveSpecialistId}
            />
          ))}
        </Styled.SpecialistsGridList>
      </Styled.SpecialistsGrid>
      <Modal isShow={showModal} onChangeShowModal={setShowModal} maxWidth="800">
        {dataSpecialists.map((specialist) => {
          if (specialist.id === activeSpecialistId) {
            return (
              <Fragment key={specialist.id}>
                <SpecialistModal
                  name={specialist.name}
                  position={specialist.position}
                  education={specialist.education}
                />
              </Fragment>
            );
          }
          return null;
        })}
      </Modal>
    </Container>
  );
};

export default SpecialistsGrid;
