import { register } from "swiper/element/bundle";

import Container from "../../../layout/container/Container";
import * as Styled from "./styles";
import { Fragment, useEffect, useRef, useState } from "react";
import { dataSpecialists } from "../../../../mock/specialists";
import SpecialistCard from "../specialist-card/SpecialistCard";
import SvgIcon from "../../../ui/svg-icon/SvgIcon";
import Modal from "../../../ui/modal/Modal";
import SpecialistModal from "../specialist-modal/SpecialistModal";

register();

const swiperParams = {
  breakpoints: {
    320: {
      slidesPerView: 1,
      spaceBetween: 32,
    },
    768: {
      slidesPerView: 1.1,
      spaceBetween: 32,
    },
  },
  injectStyles: [
    `
    :host {
      display: flex;
    }
    ::slotted(swiper-slide) {
      height: auto;
    }
    @media (min-width: 768px) {
      :host .swiper {
        overflow: visible;
      }
    }
    `,
  ],
};

const SpecialistsSlider = () => {
  const sliderRef = useRef(null);
  const [isStartSlider, setIsStartSlider] = useState(true);
  const [isEndSlider, setIsEndSlider] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [activeSpecialistId, setActiveSpecialistId] = useState(1);

  const prevSlideHandler = () => {
    sliderRef.current.swiper.slidePrev();
    const isStart = sliderRef.current.swiper.isBeginning;
    setIsEndSlider(false);

    if (isStart) {
      setIsStartSlider(true);
    } else {
      setIsStartSlider(false);
    }
  };

  const nextSlideHandler = () => {
    sliderRef.current.swiper.slideNext();
    const isEnd = sliderRef.current.swiper.isEnd;
    setIsStartSlider(false);

    if (isEnd) {
      setIsEndSlider(true);
    } else {
      setIsEndSlider(false);
    }
  };

  useEffect(() => {
    Object.assign(sliderRef.current, swiperParams);
    sliderRef.current.initialize();
  }, []);

  return (
    <Container>
      <Styled.Specialists>
        <Styled.SpecialistsHeader>
          <Styled.SpecialistsTitle>Специалисты</Styled.SpecialistsTitle>
          <Styled.SpecialistsHeaderLine />
          <Styled.SpecialistsSliderArrows>
            <Styled.SpecialistsSliderArrowsPrev
              type="button"
              onClick={prevSlideHandler}
              $disabled={isStartSlider}
              aria-label="Предыдущий"
            >
              <SvgIcon width="24" height="24" name="icon-arrow-left" />
            </Styled.SpecialistsSliderArrowsPrev>
            <Styled.SpecialistsSliderArrowsNext
              type="button"
              onClick={nextSlideHandler}
              $disabled={isEndSlider}
              aria-label="Следующий"
            >
              <SvgIcon width="24" height="24" name="icon-arrow-right" />
            </Styled.SpecialistsSliderArrowsNext>
          </Styled.SpecialistsSliderArrows>
        </Styled.SpecialistsHeader>
        <Styled.SpecialistsSlider>
          <swiper-container ref={sliderRef} init="false">
            {dataSpecialists.map((specialist) => (
              <swiper-slide key={specialist.id}>
                <SpecialistCard
                  id={specialist.id}
                  name={specialist.name}
                  position={specialist.position}
                  education={specialist.education}
                  image={specialist.image}
                  onShowModal={setShowModal}
                  onShowSpecialist={setActiveSpecialistId}
                />
              </swiper-slide>
            ))}
          </swiper-container>
        </Styled.SpecialistsSlider>
      </Styled.Specialists>
      <Modal isShow={showModal} onChangeShowModal={setShowModal} maxWidth="800">
        {dataSpecialists.map((specialist) => {
          if (specialist.id === activeSpecialistId) {
            return (
              <Fragment key={specialist.id}>
                <SpecialistModal
                  name={specialist.name}
                  position={specialist.position}
                  education={specialist.education}
                />
              </Fragment>
            );
          }
          return null;
        })}
      </Modal>
    </Container>
  );
};

export default SpecialistsSlider;
