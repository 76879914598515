import Container from "../container/Container";
import * as Styled from "./styles";

const Hero = () => {
  return (
    <Container>
      <Styled.Hero>
        <Styled.HeroContent>
          <Styled.HeroTitle>Дантист-Мастер</Styled.HeroTitle>
          <Styled.HeroText>Мы всегда с Вами и для Вас</Styled.HeroText>
        </Styled.HeroContent>
      </Styled.Hero>
    </Container>
  );
};

export default Hero;
