import SvgIcon from "../../../ui/svg-icon/SvgIcon";
import * as Styled from "./styles";

const ServiceCard = ({ name, text, logo }) => {
  return (
    <Styled.ServiceCard>
      <Styled.ServiceCardLogo>
        <SvgIcon width="100" height="100" name={logo} className="accent" />
      </Styled.ServiceCardLogo>
      <Styled.ServiceCardName>{name}</Styled.ServiceCardName>
      <Styled.ServiceCardText>{text}</Styled.ServiceCardText>
    </Styled.ServiceCard>
  );
};

export default ServiceCard;
