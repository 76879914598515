import styled from "styled-components";
import { device } from "../../../../thems/breakpoint";

export const SpecialistCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 30px;
  height: 100%;
  font-size: 16px;
  text-align: center;
  overflow: hidden;

  &:not(.second) {
    @media ${device.tablet} {
      flex-direction: row;
      font-size: 18px;
      text-align: left;
    }
  }
`;

export const SpecialistCardImage = styled.div`
  position: relative;
  width: 100%;
  height: 240px;
  background-color: #ea6165;
  flex-shrink: 0;

  img {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: bottom;
  }

  &:not(.second) {
    @media ${device.tablet} {
      width: 300px;
      height: auto;

      img {
        left: 50%;
        transform: translateX(-50%);
        width: 80%;
      }
    }
  }
`;

export const SpecialistCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding: 24px 16px;
  background: linear-gradient(
    45deg,
    rgba(36, 45, 51, 1) 50%,
    rgba(62, 52, 50, 1) 100%
  );

  &:not(.second) {
    @media ${device.tablet} {
      padding: 32px;
    }
  }
`;

export const SpecialistCardName = styled.h3`
  margin: 0;
  font-size: 26px;
  font-weight: 600;

  &:not(.second) {
    @media ${device.tablet} {
      font-size: 34px;
    }
  }
`;

export const SpecialistCardPosition = styled.p`
  margin: 0;
  margin-top: 8px;
  margin-bottom: 32px;
  color: rgba(255, 255, 255, 0.8);

  &:not(.second) {
    @media ${device.tablet} {
      margin-bottom: 0;
    }
  }
`;

export const SpecialistCardButtons = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-top: 32px;
  margin-top: auto;

  &:not(.second) {
    @media ${device.tablet} {
      justify-content: flex-start;
      margin-top: 32px;
    }
  }
`;
