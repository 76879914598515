import * as Styled from "./styles";
import AboutInfo from "../../blocks/about/about-info/AboutInfo";
import Documents from "../../blocks/documents/Documents";
import { Helmet } from "react-helmet";
import Gallery from "../../blocks/gallery/Gallery";

const PageAbout = () => {
  return (
    <>
      <Helmet>
        <title>Информация о клинике</title>
        <meta
          name="description"
          content="В этом разделе представлена общая информация о клинике. Список документов, которые могут потребоваться пациентам. Фотографии клиники, включая ее интерьер, кабинеты"
        />
        <meta
          name="keywords"
          content="стоматология, стоматология ижевск, ижевск, телефон стоматологии, врачи стоматологии, стоматология цены, сайт стоматологии, ул. Союзная 107, зуб, имплантация, пародонтология, протезирование, рентген-диагностика, терапия, хирургия"
        />
        <meta property="og:title" content="Информация о клинике" />
        <meta name="description" content="Helmet application" />
      </Helmet>
      <AboutInfo />
      <Styled.SectionDocument>
        <Documents />
        <Gallery />
      </Styled.SectionDocument>
    </>
  );
};

export default PageAbout;
