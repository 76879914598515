import Container from "../../../layout/container/Container";
import * as Styled from "./styles";

const AboutInfo = () => {
  return (
    <Styled.AboutInfo>
      <Container>
        <Styled.AboutInfoTitle>ООО «СП Дантист-Мастер»</Styled.AboutInfoTitle>
        <Styled.AboutInfoText>
          Свидетельство о государственной регистрации юридического лица в ЕГРЮЛ
          в отношении юридического лица: серия 18 № 003419260, выдан Межрайонной
          инспекцией Федеральной налоговой службы № 8 по Удмуртской Республике
          26 августа 2014г. ОГРН: 1141840007341
        </Styled.AboutInfoText>
        <Styled.AboutInfoText>
          Лицензия на осуществление медицинской деятельности № ЛО-18-01-001620
          от 13 февраля 2015г.
        </Styled.AboutInfoText>
        <Styled.AboutInfoText>Перечень работ (услуг):</Styled.AboutInfoText>
        <Styled.AboutInfoText>
          При оказании первичной доврачебной медико-санитарной помощи в
          амбулаторных условиях по: рентгенологии; сестринскому делу;
          стоматологии ортопедической;
        </Styled.AboutInfoText>
        <Styled.AboutInfoText>
          При оказании первичной специализированной медико-санитарной помощи в
          амбулаторных условиях по: стоматологии общей практики; стоматологии
          ортопедической; стоматологии терапевтической; стоматологии
          хирургической.
        </Styled.AboutInfoText>
        <Styled.AboutInfoText>
          Лицензирующий орган: Управление по лицензированию медицинской и
          фармацевтической деятельности при Правительстве Удмуртской Республики
          (УЛМД УР). Адрес: г. Ижевск, ул. Воткинское шоссе, д. 57. Тел. (3412)
          20-49-11
        </Styled.AboutInfoText>
      </Container>
    </Styled.AboutInfo>
  );
};

export default AboutInfo;
