export const defaultTheme = {
  widthContent: "1200px",
  marginSection: "120px",

  accentColor: "#ea6165",

  bgColor: "#1E252B",

  textColor: "#FFFFFF",
  textColorOpacity90: "rgba(255, 255, 255, 0.9)",
  textColorOpacity60: "rgba(255, 255, 255, 0.6)",
  textAccent: "#ea6165",

  serviceCardBg: "#242D33",
  serviceCardBgHover:
    "linear-gradient(45deg, rgba(36,45,51,1) 50%, rgba(62,52,50,1) 100%)",

  feedbackWidth: "800px",
  feedbackBgHover: "#ea6165",
  feedbackBorderRadius: "16px",

  inputBg: "#283036",
  inputBorderRadius: "6px",

  buttonBorderRadius: "6px",
  buttonAccentColor: "#FFF",
  buttonAccentBg: "#ea6165",

  switchBg: "#242D33",
  swithBgActive: "#ea6165",

  footerBg: "#242D33",
};
