import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const NavMobile = styled.nav`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  width: 300px;
  padding: 16px;
  background-color: #ea6165;
  transform: translateX(300px);
`;

export const NavMobileList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
`;

export const NavMobileLink = styled(NavLink)`
  padding: 16px 0;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
`;
