import Container from "../../layout/container/Container";
import { privacyData } from "../../../data/privacyData";
import { Helmet } from "react-helmet";

const PagePrivacy = () => {
  return (
    <>
      <Helmet>
        <title>Политика конфиденциальности</title>
        <meta
          name="description"
          content="Политика конфиденциальности клиники"
        />
        <meta
          name="keywords"
          content="стоматология, стоматология ижевск, ижевск, телефон стоматологии, врачи стоматологии, стоматология цены, сайт стоматологии, ул. Союзная 107, зуб, имплантация, пародонтология, протезирование, рентген-диагностика, терапия, хирургия"
        />
        <meta
          property="og:title"
          content="Дантист Мастер - Политика конфиденциальности"
        />
      </Helmet>
      <Container>
        <div dangerouslySetInnerHTML={{ __html: privacyData }}></div>
      </Container>
    </>
  );
};

export default PagePrivacy;
