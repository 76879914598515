import styled from "styled-components";

export const Svg = styled.svg`
  fill: #fff;

  &.accent {
    fill: ${(props) => props.theme.accentColor};
    color: ${(props) => props.theme.accentColor};
  }
`;

const SvgIcon = ({ width = 30, height = 30, name, className }) => {
  return name ? (
    <Svg width={width} height={height} className={className}>
      <use xlinkHref={`#${name}`}></use>
    </Svg>
  ) : null;
};

export default SvgIcon;
