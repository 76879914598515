import { Fragment, useEffect, useState } from "react";
import { privacyData } from "../../../data/privacyData";
import Input from "../../ui/input/Input";
import Modal from "../../ui/modal/Modal";
import ToggleSwitch from "../../ui/toggle-switch/ToggleSwitch";
import * as Styled from "./styles";
import { useContactForm } from "./utils/useContactForm";
import { dataSpecialists } from "../../../mock/specialists";
import SvgIcon from "../../ui/svg-icon/SvgIcon";
import SpecialistModal from "../../blocks/specialists/specialist-modal/SpecialistModal";
import PhoneInput from "react-phone-number-input";

const Feedback = () => {
  const [showModalPrivacy, setShowModalPrivacy] = useState(false);
  const [showModalSpecialist, setShowModalSpecialist] = useState(false);
  const [activeSpecialist, setActiveSpecialist] = useState(0);
  const [isDisabledButton, setIsDisabledButton] = useState(true);
  const {
    firstName,
    lastName,
    phone,
    comment,
    isSpecialists,
    specialistSelected,
    visited,
    isSending,
    success,
    error,
    onSubmissingForm,
    onChangeFirstName,
    onChangeLasttName,
    onChangePhone,
    onChangeComment,
    onChangeSpecialists,
    onChangeSpecialist,
    onChangeVisited,
  } = useContactForm();

  useEffect(() => {
    if (firstName.length > 0 && lastName.length > 0 && phone.length > 0) {
      setIsDisabledButton(false);
    } else {
      setIsDisabledButton(true);
    }
  }, [firstName, lastName, phone]);

  const handlerSpecialistActive = (id) => {
    setActiveSpecialist(id);
    setShowModalSpecialist(true);
  };

  return (
    <Styled.FeedbackWrapper id="feedback">
      <Styled.FeedbackContainer>
        <Styled.Feedback>
          <Styled.FeedbackTitle>Обратный звонок</Styled.FeedbackTitle>
          <Styled.FeedbackText>
            Заполните данные и в скором времени с вами свяжется наш
            администратор.
          </Styled.FeedbackText>
          <Styled.FeedbackForm onSubmit={onSubmissingForm}>
            <Styled.FeedbackFormRow>
              <Input
                type="text"
                value={firstName}
                name="first_name"
                onChange={onChangeFirstName}
                placeholder="Имя"
                autoComplete="given-name"
                required
              />
              <Input
                type="text"
                value={lastName}
                name="last_name"
                onChange={onChangeLasttName}
                placeholder="Фамилия"
                autoComplete="family-name"
                required
              />
            </Styled.FeedbackFormRow>
            <Styled.FeedbackFormRow>
              <Styled.FeedbackPhone
                htmlFor="phone"
                className={phone?.length ? "not-empty" : "empty"}
              >
                <PhoneInput
                  defaultCountry="RU"
                  value={phone}
                  onChange={onChangePhone}
                  name="phone"
                  id="phone"
                  required
                />
                <Styled.FeedbackPhoneText>
                  Введите номер телефона <span>*</span>
                </Styled.FeedbackPhoneText>
              </Styled.FeedbackPhone>
              {/* <Input
                type="tel"
                value={phone}
                onChange={onChangePhone}
                name="phone"
                placeholder="Телефон"
                autoComplete="tel"
                required
              /> */}
            </Styled.FeedbackFormRow>
            <Styled.FeedbackFormRow>
              <ToggleSwitch
                onChange={onChangeSpecialists}
                value={isSpecialists}
                checked={isSpecialists}
                text="Выбрать специалиста"
                name="specialist"
              />
            </Styled.FeedbackFormRow>
            {isSpecialists && (
              <Styled.FeedbackFormRow className="specialists">
                <Styled.FeedbackSpecialistList>
                  {dataSpecialists.map((specialist) => (
                    <Styled.FeedbackSpecialist key={specialist.id}>
                      <Styled.FeedbackSpecialistInput
                        type="radio"
                        name="specialist"
                        value={specialist.name}
                        className="visually-hidden"
                        onChange={(e) => onChangeSpecialist(e, specialist.name)}
                        checked={
                          specialistSelected === specialist.name ? true : false
                        }
                      />
                      <Styled.FeedbackSpecialistContent>
                        <Styled.FeedbackSpecialistSelect>
                          <SvgIcon
                            name="icon-check-mark"
                            width="16"
                            height="16"
                          />
                        </Styled.FeedbackSpecialistSelect>
                        <Styled.FeedbackSpecialistImage
                          src={specialist.image.webp}
                        />
                        {specialist.education && (
                          <Styled.FeedbackSpecialistButton
                            type="button"
                            onClick={() =>
                              handlerSpecialistActive(specialist.id)
                            }
                          >
                            <SvgIcon
                              name="icon-info-outline"
                              width="20"
                              height="20"
                            />
                          </Styled.FeedbackSpecialistButton>
                        )}
                      </Styled.FeedbackSpecialistContent>
                    </Styled.FeedbackSpecialist>
                  ))}
                </Styled.FeedbackSpecialistList>
              </Styled.FeedbackFormRow>
            )}
            {isSpecialists && specialistSelected && (
              <Styled.FeedbackFormRow>
                Выбранный специалист: {specialistSelected}
              </Styled.FeedbackFormRow>
            )}
            <Styled.FeedbackFormRow>
              <Input
                selector="textarea"
                value={comment}
                onChange={onChangeComment}
                name="comment"
                placeholder="Комментарий"
              />
            </Styled.FeedbackFormRow>
            <Styled.FeedbackFormFooter>
              <ToggleSwitch
                onChange={onChangeVisited}
                value={visited}
                checked={visited}
                text="Уже посещали нашу клинику"
                name="visited"
              />
              <Styled.FeedbackFormButton
                type="submit"
                className={isDisabledButton ? "disabled" : ""}
              >
                Записаться
              </Styled.FeedbackFormButton>
              <Styled.FeedbackFormFooterPrivacy>
                Нажимая кнопку "Записаться" вы соглашаетесь с{" "}
                <button type="button" onClick={() => setShowModalPrivacy(true)}>
                  политикой конфиденциальности
                </button>
                .
              </Styled.FeedbackFormFooterPrivacy>
              {error && (
                <p>
                  Произошла ошибка, повторите попытку позже или позвоните нам..
                </p>
              )}

              {isSending && <p>Идет отправка...</p>}

              {success && <p>Ваша заявка успешно отправлена!</p>}
            </Styled.FeedbackFormFooter>
          </Styled.FeedbackForm>
        </Styled.Feedback>
      </Styled.FeedbackContainer>
      <Modal
        isShow={showModalPrivacy}
        onChangeShowModal={setShowModalPrivacy}
        maxWidth="800"
      >
        <div dangerouslySetInnerHTML={{ __html: privacyData }}></div>
      </Modal>
      <Modal
        isShow={showModalSpecialist}
        onChangeShowModal={setShowModalSpecialist}
        maxWidth="800"
      >
        {dataSpecialists.map((specialist) => {
          if (specialist.id === activeSpecialist) {
            return (
              <Fragment key={specialist.id}>
                <SpecialistModal
                  name={specialist.name}
                  position={specialist.position}
                  education={specialist.education}
                />
              </Fragment>
            );
          }
          return null;
        })}
      </Modal>
    </Styled.FeedbackWrapper>
  );
};

export default Feedback;
