import Logo from "../../../../ui/logo/Logo";
import * as Styled from "./styles";
import { forwardRef } from "react";

const NavMobile = forwardRef(({ navLinkList }, ref) => {
  return (
    <Styled.NavMobile ref={ref}>
      <Logo />
      <Styled.NavMobileList>
        {navLinkList.map((link, index) => (
          <Styled.NavMobileLink
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active" : ""
            }
            to={link.href}
            key={index}
          >
            {link.name}
          </Styled.NavMobileLink>
        ))}
      </Styled.NavMobileList>
    </Styled.NavMobile>
  );
});

export default NavMobile;
