import Button from "../../ui/button/Button";
import LinkDefault from "../../ui/link-default/LinkDefault";
import SvgIcon from "../../ui/svg-icon/SvgIcon";
import Container from "../container/Container";
import * as Styled from "./styles";

const Contacts = () => {
  return (
    <Container>
      <Styled.Contacts>
        <Styled.ContactsTitle>Контакты</Styled.ContactsTitle>
        <Styled.ContactsList>
          <Styled.ContactsListItem>
            <Styled.Contact className="address">
              <Styled.ContactHeader>
                <Styled.ContactHeaderIcon>
                  <SvgIcon name="icon-home" width="24" height="24" />
                </Styled.ContactHeaderIcon>
                <Styled.ContactHeaderTitle>
                  Где нас найти?
                </Styled.ContactHeaderTitle>
              </Styled.ContactHeader>
              <Styled.ContactInfo>
                <p>Мы находимся по адресу: Ижевск, ул. Союзная, 107</p>
              </Styled.ContactInfo>
              <Styled.ContactButtons>
                <Button
                  link="https://yandex.ru/maps/-/CDeUyPN2"
                  target="_blank"
                  className="outline"
                  svgName="icon-out"
                >
                  Яндекс Карты
                </Button>
                <Button
                  link="https://go.2gis.com/aox7m"
                  target="_blank"
                  className="outline"
                  svgName="icon-out"
                >
                  2ГИС
                </Button>
              </Styled.ContactButtons>
            </Styled.Contact>
          </Styled.ContactsListItem>
          <Styled.ContactsListItem>
            <Styled.Contact className="phone">
              <Styled.ContactHeader>
                <Styled.ContactHeaderIcon>
                  <SvgIcon name="icon-phone" width="24" height="24" />
                </Styled.ContactHeaderIcon>
                <Styled.ContactHeaderTitle>
                  Как связаться?
                </Styled.ContactHeaderTitle>
              </Styled.ContactHeader>
              <Styled.ContactInfo>
                <p>
                  Почта:{" "}
                  <LinkDefault to="mailto:dantist-master@mail.ru">
                    dantist-master@mail.ru
                  </LinkDefault>
                </p>
                <p>
                  Телефоны:{" "}
                  <LinkDefault to="tel:+73412371131">
                    +7 (3412) 37-11-31
                  </LinkDefault>
                  ,{" "}
                  <LinkDefault to="tel:+79821200084">
                    +7 (982) 120-00-84
                  </LinkDefault>
                </p>
                <p>
                  Viber:{" "}
                  <LinkDefault to="viber://contact?number=%2B79821200084">
                    +7 (982) 120-00-84
                  </LinkDefault>
                </p>
              </Styled.ContactInfo>
            </Styled.Contact>
          </Styled.ContactsListItem>
          <Styled.ContactsListItem>
            <Styled.Contact className="schedule">
              <Styled.ContactHeader>
                <Styled.ContactHeaderIcon>
                  <SvgIcon name="icon-calendar" width="24" height="24" />
                </Styled.ContactHeaderIcon>
                <Styled.ContactHeaderTitle>
                  Режим работы:
                </Styled.ContactHeaderTitle>
              </Styled.ContactHeader>
              <Styled.ContactInfo>
                <p>Мы работаем с 8:00 до 20:00</p>
                <p>В субботу с 8:00 до 14:00</p>
                <p>Воскресенье - выходной</p>
              </Styled.ContactInfo>
            </Styled.Contact>
          </Styled.ContactsListItem>
        </Styled.ContactsList>
      </Styled.Contacts>
    </Container>
  );
};

export default Contacts;
