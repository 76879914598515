import SvgIcon from "../svg-icon/SvgIcon";
import * as Styled from "./styles";

const Button = ({
  type,
  children,
  link,
  onClick,
  className,
  size = "default",
  svgName,
  svgWidth = 16,
  svgHeight = 16,
  ...props
}) => {
  return (
    <Styled.Button
      {...props}
      {...(link
        ? { as: "a", href: link, onClick }
        : { as: "button", onClick, type: type ? type : "button" })}
      $size={size}
      className={className}
    >
      {svgName && (
        <SvgIcon name={svgName} width={svgWidth} height={svgHeight} />
      )}
      {children}
    </Styled.Button>
  );
};

export default Button;
