import styled, { css } from "styled-components";
import { device } from "../../../../thems/breakpoint";

export const Specialists = styled.section`
  position: relative;
  margin-top: 120px;
`;

export const SpecialistsHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;
  gap: 8px 32px;

  @media ${device.mobileL} {
    justify-content: flex-start;
  }
`;

export const SpecialistsTitle = styled.h2`
  margin: 0;
  font-size: 32px;
  font-weight: 600;

  @media ${device.mobileL} {
    font-size: 48px;
  }
`;

export const SpecialistsHeaderLine = styled.div`
  display: none;
  width: 100%;
  max-width: 200px;
  height: 2px;
  background-color: #ea6165;

  @media ${device.mobileL} {
    display: block;
  }
`;

export const SpecialistsSliderArrows = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex-basis: 100%;
  margin-left: auto;

  @media ${device.mobileL} {
    flex-basis: auto;
  }

  @media ${device.tablet} {
    position: relative;
  }
`;

const buttonArrow = css`
  position: absolute;
  top: 310px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 50%;
  background-color: #fff;
  color: #1e252b;
  cursor: pointer;
  transition: background-color 0.15s linear, color 0.15s linear;
  z-index: 2;

  ${(props) => {
    if (props.$disabled) {
      return css`
        opacity: 0.3;
        pointer-events: none;
      `;
    }
  }}

  &:hover {
    color: #fff;
    background-color: #ea6165;
  }

  @media ${device.tablet} {
    position: static;
  }
`;

export const SpecialistsSliderArrowsPrev = styled.button`
  ${buttonArrow};
  left: -5px;

  @media ${device.mobileL} {
    left: -18px;
  }
`;

export const SpecialistsSliderArrowsNext = styled.button`
  ${buttonArrow};
  right: -5px;

  @media ${device.mobileL} {
    right: -18px;
  }
`;

export const SpecialistsSlider = styled.div`
  margin-top: 40px;
  width: 100%;

  swiper-container {
    height: 100%;
  }

  @media ${device.mobileL} {
    margin-top: 60px;
  }

  @media ${device.tablet} {
    width: 80%;
  }
`;
