import Container from "../container/Container";
import * as Styled from "./styles";
import LinkDefault from "../../ui/link-default/LinkDefault";
import { navLinkList } from "../../../data/navLinkList";
import Logo from "../../ui/logo/Logo";
import SvgIcon from "../../ui/svg-icon/SvgIcon";
import Button from "../../ui/button/Button";

const Footer = () => {
  return (
    <Styled.Footer>
      <Container>
        <Styled.FooterNav>
          <Styled.FooterNavTitle>Страницы</Styled.FooterNavTitle>
          <Styled.FooterNavList>
            {navLinkList.map((link, index) => (
              <Styled.FooterNavLink
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "active" : ""
                }
                to={link.href}
                key={index}
              >
                {link.name}
              </Styled.FooterNavLink>
            ))}
          </Styled.FooterNavList>
        </Styled.FooterNav>
        <Styled.FooterContent>
          <Styled.FooterContentCell>
            <Logo />
            <p>
              Имеются противопоказания, необходимо проконсультироваться со
              специалистом
            </p>
          </Styled.FooterContentCell>
          <Styled.FooterContentCell>
            <Styled.FooterContentCellTitle>
              Контакты
            </Styled.FooterContentCellTitle>
            <Styled.FooterInfo>
              <SvgIcon name="icon-home" width="16" height="16" />
              <Styled.FooterInfoContent>
                Ижевск, ул. Союзная, 107
                <Styled.FooterInfoButtons>
                  <Button
                    link="https://yandex.ru/maps/-/CDeUyPN2"
                    target="_blank"
                    className="outline"
                    svgName="icon-out"
                    size="sm"
                  >
                    Яндекс Карты
                  </Button>
                  <Button
                    link="https://go.2gis.com/aox7m"
                    target="_blank"
                    className="outline"
                    svgName="icon-out"
                    size="sm"
                  >
                    2ГИС
                  </Button>
                </Styled.FooterInfoButtons>
              </Styled.FooterInfoContent>
            </Styled.FooterInfo>
            <Styled.FooterInfo>
              <SvgIcon name="icon-email-fill" width="16" height="16" />
              <Styled.FooterInfoContent>
                <LinkDefault to="mailto:dantist-master@mail.ru">
                  dantist-master@mail.ru
                </LinkDefault>
              </Styled.FooterInfoContent>
            </Styled.FooterInfo>
            <Styled.FooterInfo>
              <SvgIcon name="icon-phone" width="16" height="16" />
              <Styled.FooterInfoContent>
                <LinkDefault to="tel:+73412371131">
                  +7 (3412) 37-11-31
                </LinkDefault>
                <LinkDefault to="tel:+79821200084">
                  +7 (982) 120-00-84
                </LinkDefault>
              </Styled.FooterInfoContent>
            </Styled.FooterInfo>
          </Styled.FooterContentCell>
          <Styled.FooterContentCell>
            <Styled.FooterContentCellTitle>
              Режим работы
            </Styled.FooterContentCellTitle>
            <Styled.FooterInfo>
              <SvgIcon name="icon-calendar" width="16" height="16" />
              <Styled.FooterInfoContent>
                <p>Мы работаем с 8:00 до 20:00</p>
                <p>В субботу с 8:00 до 14:00</p>
                <p>Воскресенье - выходной</p>
              </Styled.FooterInfoContent>
            </Styled.FooterInfo>
          </Styled.FooterContentCell>
        </Styled.FooterContent>
        <Styled.FooterCoopyright>
          © 2020 «Дантист-мастер |{" "}
          <LinkDefault to="/privacy/">Политика конфиденциальности</LinkDefault>
        </Styled.FooterCoopyright>
      </Container>
    </Styled.Footer>
  );
};

export default Footer;
