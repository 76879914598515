import { useEffect } from "react";
import { useClickAway } from "@uidotdev/usehooks";
import SvgIcon from "../svg-icon/SvgIcon";
import * as Styled from "./styles";
import Button from "../button/Button";

const Modal = ({
  isShow = false,
  onChangeShowModal,
  header,
  footer,
  children,
  className,
  maxWidth,
}) => {
  const ref = useClickAway(() => {
    onChangeShowModal(false);
  });

  useEffect(() => {
    window.addEventListener("keydown", (event) => {
      if (event.key === "Escape") {
        onChangeShowModal(false);
      }
    });
  });

  useEffect(() => {
    if (isShow) {
      document.body.classList.add("open-modal");
    } else {
      document.body.classList.remove("open-modal");
    }
  }, [isShow]);

  return (
    <Styled.Modal $isShow={isShow} className={className}>
      <Styled.ModalDialog $maxWidth={maxWidth}>
        <Styled.ModalContent ref={ref}>
          {header && <Styled.ModalHeader>{header}</Styled.ModalHeader>}
          <Styled.ModalBody>{children}</Styled.ModalBody>
          <Styled.ModalFooter>
            <Button onClick={() => onChangeShowModal(false)}>Закрыть</Button>
          </Styled.ModalFooter>
          <Styled.ModalCross
            type="button"
            onClick={() => onChangeShowModal(false)}
          >
            <SvgIcon name="icon-cross" width="24" height="24" />
          </Styled.ModalCross>
        </Styled.ModalContent>
      </Styled.ModalDialog>
      <Styled.ModalBackdrop />
    </Styled.Modal>
  );
};

export default Modal;
