import styled, { css } from "styled-components";

const sizes = {
  default: css`
    padding: 12px 16px;
  `,
  sm: css`
    padding: 8px 12px;
    font-size: 14px;
  `,
};

export const Button = styled.button`
  display: flex;
  gap: 8px;
  align-items: center;
  border: 1.5px solid ${(props) => props.theme.buttonAccentBg};
  border-radius: ${(props) => props.theme.buttonBorderRadius};
  font-size: 14px;
  line-height: 1;
  font-weight: 600;
  color: ${(props) => props.theme.buttonAccentColor};
  background-color: ${(props) => props.theme.buttonAccentBg};
  transition: all 0.15s linear;
  cursor: pointer;

  ${({ $size }) => sizes[$size]}

  &.outline {
    border-color: rgba(255, 255, 255, 0.1);
    background-color: transparent;
    color: ${(props) => props.theme.accentColor};

    &:hover {
      border-color: ${(props) => props.theme.buttonAccentBg};
      color: ${(props) => props.theme.buttonAccentColor};
      background-color: ${(props) => props.theme.buttonAccentBg};
    }
  }

  &.link {
    border: 0;
    color: rgba(234, 97, 101, 0.9);
    background-color: transparent;
    transition: color 0.15s linear;

    &:hover {
      color: #fff;
    }
  }
`;
