import { NavLink } from "react-router-dom";
import styled from "styled-components";

const TitleSection = styled.h2`
  margin: 0;
  margin-bottom: 12px;
  font-size: 14px;
  text-transform: uppercase;
  color: ${(props) => props.theme.textColorOpacity90};
`;

export const Footer = styled.footer`
  position: relative;
  padding: 60px 0 60px;
  background-color: ${(props) => props.theme.footerBg};
  overflow: hidden;
`;

export const FooterContent = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  gap: 2rem;
  margin-top: 4rem;
`;

export const FooterContentCell = styled.div`
  max-width: 300px;
`;

export const FooterContentCellTitle = styled(TitleSection)``

export const FooterInfo = styled.div`
  display: flex;
  gap: 12px;
  font-size: 16px;

  > svg {
    transform: translateY(4px);
  }

  p {
    margin: 0;
  }

  & + & {
    margin-top: 12px;
  }
`;

export const FooterInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  > a {
    font-size: 16px;
  }
`;

export const FooterInfoButtons = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
  margin-top: 8px;
`;

export const FooterNav = styled.nav``;

export const FooterNavTitle = styled(TitleSection)``;

export const FooterNavList = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 1rem;
`;

export const FooterNavLink = styled(NavLink)`
  font-size: 14px;
  font-weight: bold;
  color: #ea6165;
  text-transform: uppercase;
`;

export const FooterCoopyright = styled.p`
  margin: 0;
  margin-top: 4rem;
  font-size: 16px;
`;
