import nasibullin_ildar_rafaelevich_webp from "../assets/specialists/nasibullin_ildar_rafaelevich.webp";
import nasibullin_ildar_rafaelevich_png from "../assets/specialists/nasibullin_ildar_rafaelevich.png";
import kasimova_liliya_fayzyrovna_webp from "../assets/specialists/kasimova_liliya_fayzyrovna.webp";
import kasimova_liliya_fayzyrovna_png from "../assets/specialists/kasimova_liliya_fayzyrovna.png";
import okulova_tatyana_vladimirovna_webp from "../assets/specialists/okulova_tatyana_vladimirovna.webp";
import okulova_tatyana_vladimirovna_png from "../assets/specialists/okulova_tatyana_vladimirovna.png";
import makarov_igor_vasilevich_webp from "../assets/specialists/makarov_igor_vasilevich.webp";
import makarov_igor_vasilevich_png from "../assets/specialists/makarov_igor_vasilevich.png";
import ermolaev_nikolay_anatolevich_webp from "../assets/specialists/ermolaev_nikolay_anatolevich.webp";
import ermolaev_nikolay_anatolevich_png from "../assets/specialists/ermolaev_nikolay_anatolevich.png";
import mamontova_mariya_alekseevna_webp from "../assets/specialists/mamontova_mariya_alekseevna.webp";
import mamontova_mariya_alekseevna_png from "../assets/specialists/mamontova_mariya_alekseevna.png";
import magomedova_tamella_farman_kyzy_webp from "../assets/specialists/magomedova_tamella_farman_kyzy.webp";
import magomedova_tamella_farman_kyzy_png from "../assets/specialists/magomedova_tamella_farman_kyzy.png";

export const dataSpecialists = [
  {
    id: 1,
    name: "Насибуллин Ильдар Рафаэлевич",
    position: "Главный врач, врач-стоматолог-ортопед, врач-стоматолог, врач-стоматолог-хирург",
    education: [
      "ГОУ ВПО «Ижевская государственная медицинская академия Федерального агентства по здравоохранению и социальному развитию», Диплом ВСГ №3593923, год окончания 2009, квалификация: Врач-стоматолог",
      "ГОУ ВПО «Ижевская государственная медицинская академия Росздрава», Диплом о профессиональной переподготовке ПП-I №304309, год окончания 2010, квалификация: Врач-стоматолог-ортопед",
      "ГБОУ ВПО «Ижевская государственная медицинская академия Минздрава России», Сертификат специалиста №0118060208966, год окончания 2015, квалификация: Врач-стоматолог-ортопед",
      "Центр дополнительного профессионального образования Нижегородской региональной общественной организации «Ассоциация специалистов по организации здравоохранения и общественному здоровью», Диплом о профессиональной переподготовке №522405084320, год окончания 2018, Сертификат специалиста №0752241523413, Направление: Организация здравоохранения и общественного здоровья",
    ],
    image: {
      webp: nasibullin_ildar_rafaelevich_webp,
      png: nasibullin_ildar_rafaelevich_png,
    },
  },
  {
    id: 2,
    name: "Касимова Лилия Файзыровна",
    position: "Врач-стоматолог-Терапевт, врач-стоматолог-ортопед",
    education: [
      "ГОУ ВПО «Ижевская государственная медицинская академия» МЗ и социального развития РФ, Диплом ВСГ №5551138, год окончания 2011. Квалификация: Врач - стоматолог.",
      "ГАУ ДПО «Институт усовершенствования врачей» Минздрава Чувашии, Сертификат специалиста №0721241474131, год окончания 2017. Квалификация: Врач - стоматолог.",
      "Многопрофильный Учебный Центр Дополнительного профессионального образования «Образовательный стандарт», Сертификат специалиста № 1177181031374, год окончания 2019, город Москва. Квалификация: Врач - стоматолог.",
      "ФГБОУ ВО ИГМА Минздрава России, Сертификат специалиста № 0118310531494, год окончания 2020. Квалификация: Врач - стоматолог - ортопед.",
    ],
    image: {
      webp: kasimova_liliya_fayzyrovna_webp,
      png: kasimova_liliya_fayzyrovna_png,
    },
  },
  {
    id: 3,
    name: "Окулова Татьяна Владимировна",
    position: "Врач стоматолог-терапевт",
    education: [
      "Ижевский государственный медицинский институт. Диплом ЭВ №140115 Год окончания 1994",
      "ООО Многопрофильный Учебный Центр Дополнительного профессионального образования «Образовательный стандарт» Сертификат специалиста №1177241788679 год окончания 2018. Квалификация Врач-стоматолог-терапевт",
    ],
    image: {
      webp: okulova_tatyana_vladimirovna_webp,
      png: okulova_tatyana_vladimirovna_png,
    },
  },
  {
    id: 4,
    name: "Макаров Игорь Васильевич",
    position: "Врач стоматолог-ортопед",
    education: [
      "Ижевский государственный медицинский институт, Диплом ТВ №015186, год окончания 1988, квалификация: Врач-стоматолог",
      "ГБОУ ВПО «Ижевская государственная медицинская академия Минздрава России», Сертификат специалиста №0118060208962, год окончания 2015, квалификация: Врач-стоматолог-ортопед",
    ],
    image: {
      webp: makarov_igor_vasilevich_webp,
      png: makarov_igor_vasilevich_png,
    },
  },
  {
    id: 6,
    name: "Ермолаев Николай Анатольевич",
    position: "Врач стоматолог-ортопед",
    education: [
      "Ижевский государственный медицинский институт, Диплом УВ №040343, год окончания 1992, квалификация: Врач-стоматолог",
      'ООО Многопрофильный Учебны Центр Дополнительного профессионального образования "Образовательный стандарт", Сертификат специалиста №1177242183145, Регистрационный № 1219-33, г. Москва, дата выдачи 19.12.2019. специальность: "Стоматология ортопедическая"',
    ],
    image: {
      webp: ermolaev_nikolay_anatolevich_webp,
      png: ermolaev_nikolay_anatolevich_png,
    },
  },
  {
    id: 7,
    name: "Мамонтова Мария Алексеевна",
    position: "Врач стоматолог",
    education: [
      "ФБГОУВП «Ижевская государственная медицинская академия» МЗ РФ г.Ижевск, диплом 101831 №0032859, год окончания 2017, квалификация: Врач-стоматолог",
    ],
    image: {
      webp: mamontova_mariya_alekseevna_webp,
      png: mamontova_mariya_alekseevna_png,
    },
  },
  {
    id: 8,
    name: "Магомедова Тамэлла Фарман Кызы",
    position: "Врач-стоматолог",
    image: {
      webp: magomedova_tamella_farman_kyzy_webp,
      png: magomedova_tamella_farman_kyzy_png,
    },
  },
  {
    id: 9,
    name: "Якимова Ольга Викторовна",
    position: "Врач-стоматолог-хирург, имплантолог",
    image: {
      webp: magomedova_tamella_farman_kyzy_webp,
      png: magomedova_tamella_farman_kyzy_png,
    },
  },
  {
    id: 10,
    name: "Потапов Максим Александрович",
    position: "Врач-стоматолог-хирург, имплантолог",
    image: {
      webp: magomedova_tamella_farman_kyzy_webp,
      png: magomedova_tamella_farman_kyzy_png,
    },
  },
  {
    id: 11,
    name: "Филимонцев Никита Сергеевич",
    position: "Врач-стоматолог-хирург, имплантолог, врач-стоматолог-ортопед",
    image: {
      webp: magomedova_tamella_farman_kyzy_webp,
      png: magomedova_tamella_farman_kyzy_png,
    },
  },
];
