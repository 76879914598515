import styled from "styled-components";

export const ServicesGrid = styled.section`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 120px;
`;

export const ServicesGridDecorative = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  opacity: 0.2;

  svg {
    width: 100%;
  }
`;

export const ServicesGridTitle = styled.h2`
  margin: 0;
  font-size: 48px;
  font-weight: 600;
  text-align: center;
`;

export const ServicesGridList = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 72px 32px;
  margin-top: 72px;
`;

export const ServicesGridListItem = styled.li``;
