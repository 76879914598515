import { Outlet } from "react-router-dom";
import Footer from "./footer/Footer";
import Feedback from "./feedback/Feedback";
import Contacts from "./contacts/Contacts";
import Hero from "./hero/Hero";
import ScrollToTop from "../../utils/scrollToTop";
import Header from "./header/Header";

export default function Layout({ children }) {
  ScrollToTop();

  return (
    <>
      <Header />
      <Hero />
      <Outlet />
      <Contacts />
      <Feedback />
      <Footer />
    </>
  );
}
