import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const Logo = styled(NavLink)`
  display: inline-flex;
  align-items: center;
  gap: 12px;
  color: #fff;
`;

export const LogoIcon = styled.div`
  display: flex;
  flex-shrink: 0;
  color: #ea6165;
`;

export const LogoInfo = styled.div``;

export const LogoInfoText = styled.p`
  flex-shrink: 0;
  margin: 0;
  font-size: 20px;
  line-height: 1;
  font-weight: 600;
  white-space: nowrap;
`;

export const LogoInfoDescr = styled.p`
  flex-shrink: 0;
  margin: 0;
  margin-top: 8px;
  font-size: 14px;
  line-height: 1;
  white-space: nowrap;
  opacity: 0.8;
`;
