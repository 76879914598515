import { getCropComment, getInitialsUser } from "../utils/utils";
import * as Styled from "./styles";

const ReviewCard = ({ id, date, name, text, onOpenReview, onShowModal }) => {
  const clickButtonMoreHandler = () => {
    onOpenReview(id);
    onShowModal(true);
  };

  return (
    <Styled.ReviewCard className="slide">
      <Styled.ReviewCardHeader>
        <Styled.ReviewCardHeaderIcon>
          {getInitialsUser(name)}
        </Styled.ReviewCardHeaderIcon>
        <Styled.ReviewCardHeaderInfo>
          <Styled.ReviewCardName>{name}</Styled.ReviewCardName>
        </Styled.ReviewCardHeaderInfo>
      </Styled.ReviewCardHeader>
      <Styled.ReviewCardText>{getCropComment(text)}</Styled.ReviewCardText>
      <Styled.ReviewCardFooter>
        <Styled.ReviewCardDate>{date}</Styled.ReviewCardDate>
        <Styled.ReviewCardButton type="button" onClick={clickButtonMoreHandler}>
          Читать
        </Styled.ReviewCardButton>
      </Styled.ReviewCardFooter>
    </Styled.ReviewCard>
  );
};

export default ReviewCard;
