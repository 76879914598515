import styled from "styled-components";

export const InputText = styled.p`
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(0, 18px, 0);
  width: 100%;
  margin: 0;
  padding: 0 16px;
  font-size: 16px;
  text-align: left;
  pointer-events: none;
  color: ${(props) => props.theme.textColorOpacity60};
  transition: transform 0.25s linear, color 0.25s linear, font-size 0.25s linear;
`;

export const InputTextRequired = styled.span`
  margin-left: 4px;
  color: ${(props) => props.theme.accentColor};
`;

export const InputField = styled.input`
  width: 100%;
  height: 60px;
  padding: 30px 16px 10px;
  border: 1.5px solid rgba(255, 255, 255, 0.1);
  font-size: 16px;
  font-weight: 400;
  font-family: inherit;
  border-radius: ${(props) => props.theme.inputBorderRadius};
  background: ${(props) => props.theme.inputBg};
  color: ${(props) => props.theme.textColor};
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;

  &:focus + ${InputText} {
    font-size: 14px;
    transform: translate3d(0, 5px, 0);
  }
`;

export const InputBox = styled.label`
  position: relative;

  &.filled ${InputText} {
    font-size: 14px;
    transform: translate3d(0, 5px, 0);
  }
`;
