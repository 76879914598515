import styled from "styled-components";
import { device } from "../../../../thems/breakpoint";

export const ReviewCard = styled.div`
  position: relative;
  padding: 32px;
  border-radius: 15px;
  transition: all 0.4s;
  background-color: #fff;
  color: black;

  &.active {
    color: black;
  }

  @media ${device.tablet} {
    background-color: transparent;
    color: #fff;
  }
`;

export const ReviewCardHeader = styled.header`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const ReviewCardHeaderIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-weight: 400;
  color: #fff;
  background-color: #ea6165;
`;

export const ReviewCardHeaderInfo = styled.div``;

export const ReviewCardName = styled.h3`
  margin: 0;
  font-size: 20px;
`;

export const ReviewCardDate = styled.p`
  margin: 0;
  font-size: 14px;
  opacity: 0.6;
`;

export const ReviewCardText = styled.p`
  display: -webkit-box;
  margin: 0;
  margin-top: 16px;
  font-size: 18px;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden;
  opacity: 0.8;
`;

export const ReviewCardFooter = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  margin-top: 16px;
`;

export const ReviewCardButton = styled.button`
  height: 32px;
  padding: 6px 16px;
  border: 0;
  border-radius: 6px;
  background-color: #ea6165;
  color: #fff;
  cursor: pointer;
`;
