import { getInitialsUser } from "../utils/utils";
import * as Styled from "./styles";

const ReviewModal = ({ name, text, date }) => {
  return (
    <Styled.ReviewModal>
      <Styled.ReviewModalImage>{getInitialsUser(name)}</Styled.ReviewModalImage>
      <Styled.ReviewModalName>{name}</Styled.ReviewModalName>
      <Styled.ReviewModalDate>{date}</Styled.ReviewModalDate>
      <Styled.ReviewModalText>{text}</Styled.ReviewModalText>
    </Styled.ReviewModal>
  );
};

export default ReviewModal;
