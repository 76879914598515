import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Container from "../../layout/container/Container";
import * as Styled from "./styles";

import photo_1 from "../../../assets/gallery/photo_1.webp";
import photo_thumbnail_1 from "../../../assets/gallery/photo_thumbnail_1.webp";
import photo_2 from "../../../assets/gallery/photo_2.webp";
import photo_thumbnail_2 from "../../../assets/gallery/photo_thumbnail_2.webp";
import photo_3 from "../../../assets/gallery/photo_3.webp";
import photo_thumbnail_3 from "../../../assets/gallery/photo_thumbnail_3.webp";
import photo_4 from "../../../assets/gallery/photo_4.webp";
import photo_thumbnail_4 from "../../../assets/gallery/photo_thumbnail_4.webp";
import photo_5 from "../../../assets/gallery/photo_5.webp";
import photo_thumbnail_5 from "../../../assets/gallery/photo_thumbnail_5.webp";
import photo_6 from "../../../assets/gallery/photo_6.webp";
import photo_thumbnail_6 from "../../../assets/gallery/photo_thumbnail_6.webp";
import photo_7 from "../../../assets/gallery/photo_7.webp";
import photo_thumbnail_7 from "../../../assets/gallery/photo_thumbnail_7.webp";
import photo_8 from "../../../assets/gallery/photo_8.webp";
import photo_thumbnail_8 from "../../../assets/gallery/photo_thumbnail_8.webp";
import photo_9 from "../../../assets/gallery/photo_9.webp";
import photo_thumbnail_9 from "../../../assets/gallery/photo_thumbnail_9.webp";
import photo_10 from "../../../assets/gallery/photo_10.webp";
import photo_thumbnail_10 from "../../../assets/gallery/photo_thumbnail_10.webp";
import photo_11 from "../../../assets/gallery/photo_11.webp";
import photo_thumbnail_11 from "../../../assets/gallery/photo_thumbnail_11.webp";
import photo_12 from "../../../assets/gallery/photo_12.webp";
import photo_thumbnail_12 from "../../../assets/gallery/photo_thumbnail_12.webp";
import photo_13 from "../../../assets/gallery/photo_13.webp";
import photo_thumbnail_13 from "../../../assets/gallery/photo_thumbnail_13.webp";
import photo_14 from "../../../assets/gallery/photo_14.webp";
import photo_thumbnail_14 from "../../../assets/gallery/photo_thumbnail_14.webp";
import photo_15 from "../../../assets/gallery/photo_15.webp";
import photo_thumbnail_15 from "../../../assets/gallery/photo_thumbnail_15.webp";
import photo_16 from "../../../assets/gallery/photo_16.webp";
import photo_thumbnail_16 from "../../../assets/gallery/photo_thumbnail_16.webp";
import photo_17 from "../../../assets/gallery/photo_17.webp";
import photo_thumbnail_17 from "../../../assets/gallery/photo_thumbnail_17.webp";
import photo_18 from "../../../assets/gallery/photo_18.webp";
import photo_thumbnail_18 from "../../../assets/gallery/photo_thumbnail_18.webp";
import photo_19 from "../../../assets/gallery/photo_19.webp";
import photo_thumbnail_19 from "../../../assets/gallery/photo_thumbnail_19.webp";
import photo_20 from "../../../assets/gallery/photo_20.webp";
import photo_thumbnail_20 from "../../../assets/gallery/photo_thumbnail_20.webp";
import photo_21 from "../../../assets/gallery/photo_21.webp";
import photo_thumbnail_21 from "../../../assets/gallery/photo_thumbnail_21.webp";
import photo_22 from "../../../assets/gallery/photo_22.webp";
import photo_thumbnail_22 from "../../../assets/gallery/photo_thumbnail_22.webp";
import photo_23 from "../../../assets/gallery/photo_23.webp";
import photo_thumbnail_23 from "../../../assets/gallery/photo_thumbnail_23.webp";
import photo_24 from "../../../assets/gallery/photo_24.webp";
import photo_thumbnail_24 from "../../../assets/gallery/photo_thumbnail_24.webp";
import photo_25 from "../../../assets/gallery/photo_25.webp";
import photo_thumbnail_25 from "../../../assets/gallery/photo_thumbnail_25.webp";
import photo_26 from "../../../assets/gallery/photo_26.webp";
import photo_thumbnail_26 from "../../../assets/gallery/photo_thumbnail_26.webp";
import photo_27 from "../../../assets/gallery/photo_27.webp";
import photo_thumbnail_27 from "../../../assets/gallery/photo_thumbnail_27.webp";
import photo_28 from "../../../assets/gallery/photo_28.webp";
import photo_thumbnail_28 from "../../../assets/gallery/photo_thumbnail_28.webp";
import photo_29 from "../../../assets/gallery/photo_29.webp";
import photo_thumbnail_29 from "../../../assets/gallery/photo_thumbnail_29.webp";
import photo_30 from "../../../assets/gallery/photo_30.webp";
import photo_thumbnail_30 from "../../../assets/gallery/photo_thumbnail_30.webp";

const images = [
  {
    original: photo_1,
    thumbnail: photo_thumbnail_1,
    loading: "lazy",
    thumbnailLoading: "lazy",
    originalAlt: "",
  },
  {
    original: photo_2,
    thumbnail: photo_thumbnail_2,
    loading: "lazy",
    thumbnailLoading: "lazy",
    originalAlt: "",
  },
  {
    original: photo_3,
    thumbnail: photo_thumbnail_3,
    loading: "lazy",
    thumbnailLoading: "lazy",
    originalAlt: "",
  },
  {
    original: photo_4,
    thumbnail: photo_thumbnail_4,
    loading: "lazy",
    thumbnailLoading: "lazy",
    originalAlt: "",
  },
  {
    original: photo_5,
    thumbnail: photo_thumbnail_5,
    loading: "lazy",
    thumbnailLoading: "lazy",
    originalAlt: "",
  },
  {
    original: photo_6,
    thumbnail: photo_thumbnail_6,
    loading: "lazy",
    thumbnailLoading: "lazy",
    originalAlt: "",
  },
  {
    original: photo_7,
    thumbnail: photo_thumbnail_7,
    loading: "lazy",
    thumbnailLoading: "lazy",
    originalAlt: "",
  },
  {
    original: photo_8,
    thumbnail: photo_thumbnail_8,
    loading: "lazy",
    thumbnailLoading: "lazy",
    originalAlt: "",
  },
  {
    original: photo_9,
    thumbnail: photo_thumbnail_9,
    loading: "lazy",
    thumbnailLoading: "lazy",
    originalAlt: "",
  },
  {
    original: photo_10,
    thumbnail: photo_thumbnail_10,
    loading: "lazy",
    thumbnailLoading: "lazy",
    originalAlt: "",
  },
  {
    original: photo_11,
    thumbnail: photo_thumbnail_11,
    loading: "lazy",
    thumbnailLoading: "lazy",
    originalAlt: "",
  },
  {
    original: photo_12,
    thumbnail: photo_thumbnail_12,
    loading: "lazy",
    thumbnailLoading: "lazy",
    originalAlt: "",
  },
  {
    original: photo_13,
    thumbnail: photo_thumbnail_13,
    loading: "lazy",
    thumbnailLoading: "lazy",
    originalAlt: "",
  },
  {
    original: photo_14,
    thumbnail: photo_thumbnail_14,
    loading: "lazy",
    thumbnailLoading: "lazy",
    originalAlt: "",
  },
  {
    original: photo_15,
    thumbnail: photo_thumbnail_15,
    loading: "lazy",
    thumbnailLoading: "lazy",
    originalAlt: "",
  },
  {
    original: photo_16,
    thumbnail: photo_thumbnail_16,
    loading: "lazy",
    thumbnailLoading: "lazy",
    originalAlt: "",
  },
  {
    original: photo_17,
    thumbnail: photo_thumbnail_17,
    loading: "lazy",
    thumbnailLoading: "lazy",
    originalAlt: "",
  },
  {
    original: photo_18,
    thumbnail: photo_thumbnail_18,
    loading: "lazy",
    thumbnailLoading: "lazy",
    originalAlt: "",
  },
  {
    original: photo_19,
    thumbnail: photo_thumbnail_19,
    loading: "lazy",
    thumbnailLoading: "lazy",
    originalAlt: "",
  },
  {
    original: photo_20,
    thumbnail: photo_thumbnail_20,
    loading: "lazy",
    thumbnailLoading: "lazy",
    originalAlt: "",
  },
  {
    original: photo_21,
    thumbnail: photo_thumbnail_21,
    loading: "lazy",
    thumbnailLoading: "lazy",
    originalAlt: "",
  },
  {
    original: photo_22,
    thumbnail: photo_thumbnail_22,
    loading: "lazy",
    thumbnailLoading: "lazy",
    originalAlt: "",
  },
  {
    original: photo_23,
    thumbnail: photo_thumbnail_23,
    loading: "lazy",
    thumbnailLoading: "lazy",
    originalAlt: "",
  },
  {
    original: photo_24,
    thumbnail: photo_thumbnail_24,
    loading: "lazy",
    thumbnailLoading: "lazy",
    originalAlt: "",
  },
  {
    original: photo_25,
    thumbnail: photo_thumbnail_25,
    loading: "lazy",
    thumbnailLoading: "lazy",
    originalAlt: "",
  },
  {
    original: photo_26,
    thumbnail: photo_thumbnail_26,
    loading: "lazy",
    thumbnailLoading: "lazy",
    originalAlt: "",
  },
  {
    original: photo_27,
    thumbnail: photo_thumbnail_27,
    loading: "lazy",
    thumbnailLoading: "lazy",
    originalAlt: "",
  },
  {
    original: photo_28,
    thumbnail: photo_thumbnail_28,
    loading: "lazy",
    thumbnailLoading: "lazy",
    originalAlt: "",
  },
  {
    original: photo_29,
    thumbnail: photo_thumbnail_29,
    loading: "lazy",
    thumbnailLoading: "lazy",
    originalAlt: "",
  },
  {
    original: photo_30,
    thumbnail: photo_thumbnail_30,
    loading: "lazy",
    thumbnailLoading: "lazy",
    originalAlt: "",
  },
];

const Gallery = () => {
  return (
    <Styled.Gallery>
      <Container>
        <Styled.GalleryTitle>Фото клиники</Styled.GalleryTitle>
        <ImageGallery items={images} showPlayButton={false} lazyLoad={true} />
      </Container>
    </Styled.Gallery>
  );
};

export default Gallery;
