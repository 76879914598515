export const navLinkList = [
  {
    id: 1,
    name: "Главная",
    href: "/",
  },
  {
    id: 2,
    name: "О нас",
    href: "about",
  },
  {
    id: 3,
    name: "Специалисты",
    href: "specialists/",
  },
  {
    id: 4,
    name: "Услуги",
    href: "services/",
  },
  {
    id: 5,
    name: "Контакты",
    href: "contacts/",
  },
];
