import styled from "styled-components";
import { device } from "../../../thems/breakpoint";
import Button from "../../ui/button/Button";

export const Header = styled.header``;

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
  padding: 32px 0;
`;

export const HeaderNav = styled.nav`
  display: none;
  align-items: center;
  gap: 16px;
  margin-left: auto;

  a {
    padding: 8px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.55);
    text-transform: uppercase;
    text-decoration: none;
    transition: color 0.15s linear;
  }

  a:hover,
  a:active,
  a:focus {
    color: rgba(255, 255, 255, 1);
    background-color: rgba(255, 255, 255, 0.1);
  }

  a.active {
    color: ${(props) => props.theme.textAccent};
    background-color: rgba(234, 97, 101, 0.1);
    pointer-events: none;
  }

  @media ${device.laptop} {
    display: flex;
  }
`;

export const HeaderButton = styled(Button)`
  display: none;

  @media ${device.laptop} {
    display: flex;
  }
`;

export const HeaderMenuIcon = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  margin-left: auto;
  padding: 0;
  border: 0;
  border-radius: 50%;
  color: #ea6165;
  background-color: transparent;
  cursor: pointer;
  overflow: hidden;

  svg {
    transform: scale(160%);
    pointer-events: none;
  }

  path {
    --length: 24;
    --offset: -38;
    fill: none;
    stroke: currentColor;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: var(--length) var(--total-length);
    stroke-dashoffset: var(--offset);
    transition: all 400ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .line--1,
  .line--3 {
    --total-length: 126.64183044433594;
  }
  .line--2 {
    --total-length: 70;
  }
  &.open {
    .line--1,
    .line--3 {
      --length: 22.627416998;
      --offset: -94.1149185097;
    }
    .line--2 {
      --length: 0;
      --offset: -50;
    }
  }

  @media ${device.laptop} {
    display: none;
  }
`;
