import { useState } from "react";
import { postData } from "./sendDataForm";

export const useContactForm = () => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    user_phone: "",
    comment: "",
    isSpecialists: false,
    specialistSelected: "",
    visited: false,
  });
  const [isSending, setSending] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const onSubmissingForm = function (e) {
    e.preventDefault();

    setSending(true);

    postData(`/send.php`, JSON.stringify(formData))
      .then((res) => {
        setSuccess(true);
        console.log(res);
      })
      .catch((e) => {
        setError(true);
        console.error(e);
      })
      .finally(() => {
        setFormData({
          first_name: "",
          last_name: "",
          user_phone: "",
          comment: "",
          isSpecialists: false,
          visited: false,
        });
        setSending(false);

        // setTimeout(() => {
        //   setSuccess(false);
        //   setError(false);
        // }, 5000);
      });
  };

  const onChangeFirstName = (e) =>
    setFormData({ ...formData, first_name: e.target.value });
  const onChangeLasttName = (e) =>
    setFormData({ ...formData, last_name: e.target.value });
  const onChangePhone = (value) =>
    setFormData({ ...formData, user_phone: value });
  const onChangeComment = (e) =>
    setFormData({ ...formData, comment: e.target.value });
  const onChangeSpecialists = (e) => {
    setFormData({ ...formData, isSpecialists: e.target.checked });
  };
  const onChangeSpecialist = (e, name) => {
    setFormData({...formData, specialistSelected: name})
  };
  const onChangeVisited = (e) =>
    setFormData({ ...formData, visited: e.target.checked });

  return {
    firstName: formData.first_name,
    lastName: formData.last_name,
    phone: formData.user_phone,
    comment: formData.comment,
    isSpecialists: formData.isSpecialists,
    specialistSelected: formData.specialistSelected,
    visited: formData.visited,
    onSubmissingForm,
    onChangeFirstName,
    onChangeLasttName,
    onChangePhone,
    onChangeComment,
    onChangeSpecialists,
    onChangeSpecialist,
    onChangeVisited,
    isSending,
    success,
    error,
  };
};
