import { Helmet } from "react-helmet";

const PageContacts = () => {
  return (
    <>
      <Helmet>
        <title>Наши контакты</title>
        <meta
          name="description"
          content="В этом разделе представлена общая информация о клинике, такая как название, местоположение, контактная информация и график работы."
        />
        <meta
          name="keywords"
          content="стоматология, стоматология ижевск, ижевск, телефон стоматологии, врачи стоматологии, стоматология цены, сайт стоматологии, ул. Союзная 107, зуб, имплантация, пародонтология, протезирование, рентген-диагностика, терапия, хирургия"
        />
        <meta property="og:title" content="Дантист Мастер - Наши контакты" />
      </Helmet>
    </>
  );
};

export default PageContacts;
