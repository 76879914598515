import SpecialistsGrid from "../../blocks/specialists/specialists-grid/SpecialistsGrid";
import { Helmet } from "react-helmet";

const PageSpecialists = () => {
  return (
    <>
      <Helmet>
        <title>Наши специалисты</title>
        <meta
          name="description"
          content="Здесь представлена информация о врачах и специалистах, работающих в клинике."
        />
        <meta
          name="keywords"
          content="стоматология, стоматология ижевск, ижевск, телефон стоматологии, врачи стоматологии, стоматология цены, сайт стоматологии, ул. Союзная 107, зуб, имплантация, пародонтология, протезирование, рентген-диагностика, терапия, хирургия"
        />
        <meta property="og:title" content="Дантист Мастер - Наши специалисты" />
      </Helmet>
      <SpecialistsGrid />
    </>
  );
};

export default PageSpecialists;
