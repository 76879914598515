import styled from "styled-components";
import { device } from "../../../thems/breakpoint";
import { InputBox } from "../../ui/input/styles";
import Button from "../../ui/button/Button";
import { Container } from "../container/styles";

export const FeedbackWrapper = styled.section`
  position: relative;
  margin-top: 120px;
  margin-bottom: 60px;

  &::before {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 50px;
    background-color: ${(props) => props.theme.footerBg};
    z-index: 1;
  }

  @media ${device.mobileL} {
    margin-bottom: 0;

    &::before {
      content: "";
    }
  }
`;

export const FeedbackContainer = styled(Container)`
  padding: 0;

  @media ${device.mobileL} {
    padding: 0 32px;
  }
`;

export const FeedbackText = styled.p`
  max-width: 420px;
  margin: 0 auto;
  margin-top: 24px;
  font-size: 18px;
  font-weight: 400;
  color: ${(props) => props.theme.textColorOpacity90};
  transition: color 0.1s linear;
  text-align: center;

  @media ${device.tablet} {
    color: ${(props) => props.theme.textColorOpacity60};
  }
`;

export const FeedbackFormFooterPrivacy = styled.p`
  flex-basis: 100%;
  margin: 0;
  margin-top: 16px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;

  button {
    margin: 0;
    padding: 0;
    border: 0;
    color: #fff;
    background-color: transparent;
    cursor: pointer;

    @media ${device.tablet} {
      color: #ea6165;
    }
  }
`;

export const FeedbackTitle = styled.h2`
  margin: 0;
  font-size: 32px;
  line-height: 1.2;
  font-weight: 600;
  text-align: center;
  color: ${(props) => props.theme.textColorOpacity90};

  @media ${device.mobileL} {
    font-size: 40px;
  }

  @media ${device.tablet} {
    font-size: 48px;
  }
`;

export const FeedbackForm = styled.form`
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  gap: 12px;
  margin: 40px auto 0;
  max-width: 500px;

  ${InputBox} {
    flex: 1;
    min-width: 240px;
  }

  textarea {
    height: 80px;
    min-height: 80px;
    resize: vertical;
  }
`;

export const FeedbackFormRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 12px;
  flex-basis: 100%;

  &.specialists {
    overflow: hidden;
  }
`;

export const FeedbackFormFooter = styled.footer`
  display: flex;
  flex-flow: row wrap;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const FeedbackFormButton = styled(Button)`
  justify-content: center;
  flex-basis: 100%;
  margin-top: 16px;
  color: #ea6165;
  background-color: #fff;

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  @media ${device.mobileL} {
    flex-basis: auto;
    margin-top: 0;
  }

  @media ${device.tablet} {
    color: ${(props) => props.theme.buttonAccentColor};
    background-color: ${(props) => props.theme.buttonAccentBg};
  }
`;

export const FeedbackPhone = styled.label`
  position: relative;
  width: 100%;
  height: 60px;
  padding: 30px 16px 10px;
  border: 1.5px solid rgba(255, 255, 255, 0.1);
  font-size: 16px;
  font-weight: 400;
  font-family: inherit;
  border-radius: ${(props) => props.theme.inputBorderRadius};
  background: ${(props) => props.theme.inputBg};
  color: ${(props) => props.theme.textColor};
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;

  select,
  .PhoneInputCountrySelectArrow {
    display: none;
  }

  .PhoneInput {
    display: flex;
  }

  &.empty .PhoneInputCountry {
    display: none;
  }

  .PhoneInputCountry {
    width: 20px;
    margin-right: 12px;
    transform: translateY(1px);
  }

  input {
    width: 100%;
    padding: 0;
    border: 0;
    color: inherit;
    font: inherit;
    background-color: transparent;
    outline: none;

    &:focus,
    &:active.
    &:hover {

    }
  }
`;

export const FeedbackPhoneText = styled.p`
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(0, 18px, 0);
  width: 100%;
  margin: 0;
  padding: 0 16px;
  font-size: 16px;
  text-align: left;
  pointer-events: none;
  color: ${(props) => props.theme.textColorOpacity60};
  transition: transform 0.25s linear, color 0.25s linear, font-size 0.25s linear;

  span {
    margin-left: 2px;
    color: ${(props) => props.theme.accentColor};
  }

  .not-empty &,
  .PhoneInput--focus + & {
    font-size: 14px;
    transform: translate3d(0, 5px, 0);
  }
`

export const FeedbackSpecialistList = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
  overflow-x: auto;
`;

export const FeedbackSpecialist = styled.label`
  position: relative;
  display: block;
  min-width: 140px;
  border-radius: 8px;
  overflow: hidden;
`;

export const FeedbackSpecialistContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 0;
  width: 100%;
  height: 100%;
  background-color: #283036;
  cursor: pointer;

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 20%;
    background: linear-gradient(
      0deg,
      rgba(40, 48, 54, 1) 0%,
      rgba(40, 48, 54, 1) 40%,
      rgba(40, 48, 54, 0) 100%
    );
    content: "";
  }
`;

export const FeedbackSpecialistSelect = styled.div`
  position: absolute;
  top: -6px;
  left: -6px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border-radius: 50%;
  background-color: rgba(30, 37, 43, 0.8);
  color: #fff;
  transform: translate(-8px, -8px);
  opacity: 0;
  transition: transform 0.3s linear, opacity 0.3s linear;
  z-index: 2;

  svg {
    position: relative;
    z-index: 2;
  }

  &::before {
    position: absolute;
    top: -8px;
    left: -8px;
    right: -8px;
    bottom: -8px;
    border-radius: 50%;
    background-color: rgba(30, 37, 43, 0.6);
    content: "";
  }

  &::after {
    position: absolute;
    top: -16px;
    left: -16px;
    right: -16px;
    bottom: -16px;
    border-radius: 50%;
    background-color: rgba(30, 37, 43, 0.4);
    content: "";
  }
`;

export const FeedbackSpecialistImage = styled.img`
  position: relative;
  width: 100%;
  height: auto;
`;

export const FeedbackSpecialistButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 0;
  color: #ea6165;
  background-color: transparent;
  cursor: pointer;
`;

export const FeedbackSpecialistInput = styled.input`
  &:checked ~ ${FeedbackSpecialistContent} {
    background-color: rgba(255, 255, 255, 1);

    ${FeedbackSpecialistSelect} {
      transform: translate(0, 0);
      opacity: 1;
    }

    ${FeedbackSpecialistButton} {
      color: #1e252b;
    }

    &::after {
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) 40%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }
`;

export const Feedback = styled.div`
  position: relative;
  max-width: ${(props) => props.theme.feedbackWidth};
  margin: 0 auto;
  padding: 32px 16px;
  border-radius: 0;
  background-color: ${(props) => props.theme.feedbackBgHover};
  transition: background-color 0.1s linear;
  z-index: 2;

  &::before,
  &::after {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    background-color: ${(props) => props.theme.feedbackBgHover};
    z-index: -1;
    content: "";
  }

  &::before {
    transform: translateY(-26px) scale(0.95);
    opacity: 0.6;
  }

  &::after {
    transform: translateY(-52px) scale(0.9);
    opacity: 0.4;
  }

  @media ${device.mobileL} {
    padding: 32px;
    border-radius: ${(props) => props.theme.feedbackBorderRadius};
  }

  @media ${device.mobileL} {
    padding: 48px;
  }

  @media ${device.tablet} {
    padding: 60px;
    background-color: ${(props) => props.theme.bgColor};

    &::before,
    &::after {
      transform: translateY(0) scale(1);
      background-color: transparent;
    }

    &::before {
      transition: background-color 0.2s linear, transform 0.4s linear;
    }

    &::after {
      transition: background-color 0.3s linear, transform 0.4s linear;
    }

    &:hover {
      background-color: ${(props) => props.theme.feedbackBgHover};

      &::before {
        transform: translateY(-26px) scale(0.95);
        background-color: ${(props) => props.theme.feedbackBgHover};
      }

      &::after {
        transform: translateY(-52px) scale(0.9);
        background-color: ${(props) => props.theme.feedbackBgHover};
      }

      ${FeedbackFormButton} {
        color: ${(props) => props.theme.feedbackBgHover};
        background-color: #fff;
      }

      ${FeedbackText} {
        color: ${(props) => props.theme.textColorOpacity90};
      }

      ${FeedbackFormFooterPrivacy} {
        button {
          color: #fff;
        }
      }
    }
  }
`;
