export const servicesData = [
  {
    id: 0,
    visible: false,
    name: "Общие виды услуг",
    text: "",
    logo: "",
    listPrice: [
      {
        name: "Прием (осмотр, консультация) врача стоматолога первичный",
        price: 400,
        selected: false,
      },
      {
        name: "Прием (осмотр, консультация) врача стоматолога повторный",
        price: 300,
        selected: false,
      },
      {
        name: "Прием (осмотр, консультация) врача-стоматолога-терапевта повторный (Прием с применением операционного микроскопа)",
        price: 2000,
        selected: false,
      },
      {
        name: "Радиовизиография",
        price: 200,
        selected: false,
      },
      {
        name: "Радиовизиография (повторная)",
        price: 100,
        selected: false,
      },
      {
        name: "Ортопантомография",
        price: 800,
        selected: false,
      },
      {
        name: "Воздействие лазерным низкоинтенсивным излучением на область десен",
        price: 20,
        selected: false,
      },
      {
        name: "Анестезия аппликационная",
        price: 100,
        selected: false,
      },
      {
        name: "Инфильтрационная анестезия",
        price: 350,
        selected: false,
      },
      {
        name: "Проводниковая анестезия",
        price: 350,
        selected: false,
      },
      {
        name: "Осмотр полости рта с помощью дополнительных инструментов (Наложение коффердама, раббердама)",
        price: 600,
        selected: false,
      },
      {
        name: "Осмотр полости рта с помощью дополнительных инструментов (Наложение минидама, квикдама, оптрагейта,оптрадама)",
        price: 400,
        selected: false,
      },
      {
        name: "Осмотр полости рта с помощью дополнительных инструментов (Наложение нити ретракционнной, драй-типсы)",
        price: 100,
        selected: false,
      },
      {
        name: "3Д КЛКТ 5х5см (один сегмент)",
        price: 1000,
        selected: false,
      },
      {
        name: "3Д КЛКТ 6х8см (одна челюсть)",
        price: 1900,
        selected: false,
      },
      {
        name: "3Д КЛКТ 8х8см (две челюсти)",
        price: 2500,
        selected: false,
      },
      {
        name: "Повторная копия исследования 3Д КЛКТ на диск",
        price: 150,
        selected: false,
      },
    ],
  },
  {
    id: 1,
    visible: true,
    name: "Имплантация",
    text: "Проводится остеопластическая подготовка к имплантации (синус-лифтинг) и внедрение в кость имплантатов.",
    logo: "logo-implantaciya",
    listPrice: [],
  },
  {
    id: 2,
    visible: true,
    name: "Пародонтология",
    text: "Лечение заболеваний десен и тканей пародонта.",
    logo: "logo-parodontologiya",
    listPrice: [
      {
        id: 1,
        name: "Прием (осмотр, консультация) врача стоматолога первичный (Консультация пародонтолога)",
        price: 400,
        selected: false,
      },
      {
        id: 2,
        name: "Визуальное исследование при патологии полости рта (Прием врача при заболеваниях слизистой оболочки полости рта)",
        price: 600,
        selected: false,
      },
      {
        id: 3,
        name: "Определение индексов гигиены полости рта",
        price: 150,
        selected: false,
      },
      {
        id: 4,
        name: "Определение пародонтальных индексов",
        price: 150,
        selected: false,
      },
      {
        id: 5,
        name: "Обучение гигиене полости рта",
        price: 150,
        selected: false,
      },
      {
        id: 6,
        name: "Профессиональная гигиена полости рта и зубов (Снятие пигментированного налета пастой Septodont или эквивалент со всех зубов полости рта)",
        price: 2600,
        selected: false,
      },
      {
        id: 7,
        name: "Профессиональная гигиена полости рта и зубов (Снятие пигментированного налета пастой Septodont или эквивалент с 1 зуба)",
        price: 150,
        selected: false,
      },
      {
        id: 8,
        name: "Профессиональная гигиена полости рта и зубов (AIR FLOW)",
        price: 3700,
        selected: false,
      },
      {
        id: 9,
        name: "Удаление наддесневых и поддесневых зубных отложений в области зуба ручным методом",
        price: 150,
        selected: false,
      },
      {
        id: 10,
        name: "Ультразвуковое удаление наддесневых и поддесневых зубных отложений в области зуба (со всех зубов полости рта)",
        price: 4400,
        selected: false,
      },
      {
        id: 11,
        name: "Ультразвуковое удаление наддесневых и поддесневых зубных отложений в области зуба",
        price: 180,
        selected: false,
      },
      {
        id: 12,
        name: "Профессиональная гигиена полости рта и зубов (КОМПЛЕКСНАЯ ПРОФЕССИОНАЛЬНАЯ ЧИСТКА)",
        price: 4850,
        selected: false,
      },
      {
        id: 13,
        name: "Профессиональное отбеливание зубов (ОТБЕЛИВАНИЕ С ИСПОЛЬЗОВАНИЕМ ДОМАШНЕЙ СИСТЕМЫ PRO WHITENING HOME KIT) (2 челюсти)",
        price: 13000,
        selected: false,
      },
      {
        id: 14,
        name: "Профессиональное отбеливание зубов (ОТБЕЛИВАНИЕ С ИСПОЛЬЗОВАНИЕМ СИСТЕМЫ «AMAZING WHITE UNIVERSAL EXTRA» (2 челюсти)",
        price: 15000,
        selected: false,
      },
      {
        id: 15,
        name: "Профессиональное отбеливание зубов (ОТБЕЛИВАНИЕ С ИСПОЛЬЗОВАНИЕМ ДИОДНОГО ЛАЗЕРА И СИСТЕМЫ «HEYDENT» (2 челюсти)",
        price: 18000,
        selected: false,
      },
      {
        id: 16,
        name: "Вскрытие подслизистого или поднадкостничного очага воспаления в полости рта",
        price: 350,
        selected: false,
      },
      {
        id: 17,
        name: "Закрытый кюретаж при заболеваниях пародонта в области зуба",
        price: 500,
        selected: false,
      },
      {
        id: 18,
        name: "Аппликация лекарственного препарата на слизистую оболочку полости рта (1 сегмент)",
        price: 150,
        selected: false,
      },
      {
        id: 19,
        name: "Наложение лечебной повязки при заболеваниях слизистой оболочки полости рта и пародонта в области одной челюсти (Репин, Сентопак)",
        price: 300,
        selected: false,
      },
      {
        id: 20,
        name: "Наложение лечебной повязки на слизистую оболочку полости рта (Диплен-дента, 1 сегмент)",
        price: 300,
        selected: false,
      },
      {
        id: 21,
        name: "Озонотерапия (ОЗОНОТЕРАПИЯ АППАРАТОМ OZONEDTA GENERATOR) (1 зуба, афты, и т.д.)",
        price: 150,
        selected: false,
      },
      {
        id: 22,
        name: "Введение лекарственных препаратов в пародонтальный карман (1-го сегмента)",
        price: 150,
        selected: false,
      },
      {
        id: 28,
        name: "Временное шинирование при заболеваниях пародонта (Шинирование 1 зуба стекловолоконным материалом)",
        price: 1600,
        selected: false,
      },
      {
        id: 29,
        name: "Временное шинирование при заболеваниях пародонта (Устранение дефекта шины при ее сохранности)",
        price: 700,
        selected: false,
      },
      {
        id: 30,
        name: "Временное шинирование при заболеваниях пародонта (Восстановление одной единицы дефекта зубного ряда с применением стекловолоконных материалов и фотополимеров)",
        price: 4000,
        selected: false,
      },
      {
        id: 31,
        name: "Временное шинирование при заболеваниях пародонта (Снятие несостоятельной шины)",
        price: 350,
        selected: false,
      },
    ],
  },
  {
    id: 3,
    visible: true,
    name: "Протезирование",
    text: "Раздел стоматологии, занимающийся восстановлением тканей зуба, замещением утраченных зубов, восстановлением структуры и функций жевательного аппарата.",
    logo: "logo-protezirovanie",
    listPrice: [],
  },
  {
    id: 4,
    visible: true,
    name: "Рентген-диагностика",
    text: "Рентген-диагностика позволяет увидеть зуб со всех сторон, распознать патологию вокруг зуба, и самое главное, взглянуть внутрь зуба, не вскрывая его.",
    logo: "logo-rentgen",
    listPrice: [],
  },
  {
    id: 5,
    visible: true,
    name: "Терапия",
    nameLatin: "terapiya",
    text: "Лечение кариеса, пульпита различной сложности, услуги по улучшению эстетического состояния зубов (реставрации при сколах, профессиональная гигиена, отбеливание зубов).",
    logo: "logo-terapiya",
    listPrice: [
      {
        name: "Местное применение реминерализующих препаратов в области зуба (всех зубов, препарат глуфторэд, APF gel)",
        price: 600,
        selected: false,
      },
      {
        name: "Запечатывание фиссуры зуба герметиком",
        price: 800,
        selected: false,
      },
      {
        name: "Диатермокоагуляция при патологии полости рта и зубов",
        price: 250,
        selected: false,
      },
      {
        name: "Электроодонтометрия",
        price: 150,
        selected: false,
      },
      {
        name: "Восстановление зуба пломбой (Наложение подкладочного материала Кальцимол (Германия))",
        price: 250,
        selected: false,
      },
      {
        name: "Восстановление зуба пломбой I, II, III, V, VI класс по Блэку с использованием стоматологических цементов (Наложение пломбировочного материала Глассин (Россия), Стомафил (Россия))",
        price: 1400,
        selected: false,
      },
      {
        name: "Восстановление зуба пломбой I, II, III, V, VI класс по Блэку с использованием стоматологических цементов (Восстановление зуба материалом SDR)",
        price: 1900,
        selected: false,
      },
      {
        name: "Восстановление зуба пломбой I, V, VI класс по Блэку с использованием материалов из фотополимеров (Эстелайт (Япония), Филтек (США), Гармонайз (США))",
        price: 2300,
        selected: false,
      },
      {
        name: "Восстановление зуба пломбой II, III класс по Блэку с использованием материалов из фотополимеров (Эстелайт (Япония), Филтек (США), Гармонайз (США))",
        price: 2300,
        selected: false,
      },
      {
        name: "Восстановление зуба пломбой IV класс по Блэку с использованием материалов из фотополимеров (Эстелайт (Япония), Филтек (США), Гармонайз (США))",
        price: 2650,
        selected: false,
      },
      {
        name: "Восстановление зуба пломбой (из фотополимеров Эстелайт (Япония), Филтек (США), Гармонайз (США) при восстановление 2-х поверхностей)",
        price: 3100,
        selected: false,
      },
      {
        name: "Восстановление зуба пломбой (из фотополимеров Эстелайт (Япония), Филтек (США), Гармонайз (США) при восстановление 3-х поверхностей)",
        price: 3600,
        selected: false,
      },
      {
        name: "Избирательное полирование зуба",
        price: 150,
        selected: false,
      },
      {
        name: "Наложение временной пломбы (Дентин - паста (Россия))",
        price: 200,
        selected: false,
      },
      {
        name: "Наложение временной пломбы (Quicks (Корея), Clip (Германия))",
        price: 350,
        selected: false,
      },
      {
        name: "Трепанация зуба, искусственной коронки, снятие пломбы",
        price: 350,
        selected: false,
      },
      {
        name: "Наложение девитализирующей пасты",
        price: 450,
        selected: false,
      },
      {
        name: "Пульпотомия (ампутация коронковой пульпы)",
        price: 350,
        selected: false,
      },
      {
        name: "Экстирпация пульпы",
        price: 350,
        selected: false,
      },
      {
        name: "Снятие временной пломбы (удаление девитализирующей пасты)",
        price: 200,
        selected: false,
      },
      {
        name: "Удаление внутриканального штифта/вкладки",
        price: 700,
        selected: false,
      },
      {
        name: "Инструментальная и медикаментозная обработка хорошо проходимого корневого канала (Машинная обработка одного корневого канала)",
        price: 900,
        selected: false,
      },
      {
        name: "Инструментальная и медикаментозная обработка плохо проходимого корневого канала (Машинная обработка одного корневого канала)",
        price: 950,
        selected: false,
      },
      {
        name: "Распломбировка корневого канала ранее леченного пастой",
        price: 350,
        selected: false,
      },
      {
        name: "Распломбировка корневого канала ранее леченного пастой (гуттаперчей)",
        price: 600,
        selected: false,
      },
      {
        name: "Распломбировка корневого канала ранее леченного фосфат-цементом, резорцин-формальдегидным методом",
        price: 850,
        selected: false,
      },
      {
        name: "Распломбировка корневого канала ранее леченного пастой (подготовка корневого канала под вкладку культевую штифтовую)",
        price: 650,
        selected: false,
      },
      {
        name: "Временное пломбирование лекарственным препаратом одного корневого канала",
        price: 450,
        selected: false,
      },
      {
        name: "Временное пломбирование лекарственным препаратом одного корневого канала (за каждый последующий канал)",
        price: 250,
        selected: false,
      },
      {
        name: "Пломбирование корневого канала зуба гуттаперчевыми штифтами (паста с моноштифтом)",
        price: 700,
        selected: false,
      },
      {
        name: "Пломбирование корневого канала зуба гуттаперчевыми штифтами (латеральная компакция с пастой)",
        price: 950,
        selected: false,
      },
      {
        name: "Пломбирование корневого канала зуба гуттаперчевыми штифтами (термопластифицированной гуттаперей (гибридный метод))",
        price: 1400,
        selected: false,
      },
      {
        name: "Пломбирование корневого канала зуба пастой (Апексификация, закрытие перфораций материалом Biodentine)",
        price: 1500,
        selected: false,
      },
      {
        name: "Фиксация внутриканального штифта/вкладки (анкерного поста)",
        price: 850,
        selected: false,
      },
      {
        name: "Фиксация внутриканального штифта/вкладки (стекловолоконного поста)",
        price: 1100,
        selected: false,
      },
    ],
  },
  {
    id: 6,
    visible: true,
    name: "Хирургия",
    text: "Широкий спектр операций на корнях зубов (в том числе удаление кисты на корне зуба, удаление экзостозов челюсти, лечение, оказывается помощь при заболеваниях слюнных желез и образованиях мягких тканей.",
    logo: "logo-hirurgiya",
    listPrice: [],
  },
];
