import * as Styled from "./styles";

const SpecialistModal = ({ name, position, education }) => {
  return (
    <>
      <Styled.SpecialistsModalName>{name}</Styled.SpecialistsModalName>
      <Styled.SpecialistsModalPosition>
        {position}
      </Styled.SpecialistsModalPosition>
      <Styled.SpecialistsModalEducations>
        <Styled.SpecialistsModalEducationsList>
          {education.map((education, index) => {
            return (
              <Styled.SpecialistsModalEducationsItem key={index}>
                <Styled.SpecialistsModalEducationsText>
                  {education}
                </Styled.SpecialistsModalEducationsText>
              </Styled.SpecialistsModalEducationsItem>
            );
          })}
        </Styled.SpecialistsModalEducationsList>
      </Styled.SpecialistsModalEducations>
    </>
  );
};

export default SpecialistModal;
