import { Fragment } from "react";
import { useEffect, useRef, useState } from "react";

import { register } from "swiper/element/bundle";

import Container from "../../layout/container/Container";
import ReviewCard from "./review-card/ReviewCard";
import * as Styled from "./styles";
import SvgIcon from "../../ui/svg-icon/SvgIcon";
import { ReactComponent as IconReviewsDecorative } from "./icon-reviews-decorative.svg";
import Modal from "../../ui/modal/Modal";
import ReviewModal from "./review-modal/ReviewModal";

register();

const reviewsData = [
  {
    id: 1,
    star: 5,
    site: "yandex",
    link: "https://yandex.ru/web-maps/org/1085319176/reviews?reviews[publicId]=frx0155zgyqrcgkk6k9mbbbd4w&utm_source=review",
    date: "18 мая 2023",
    name: "Елена Кузнецова",
    text: "Огромная благодарность лучшей стоматологической клинике г. Ижевска , клиника оснащена современным оборудованием . Персонал встречает с улыбкой, доброделательностью Лечение очень хорошое. Хочется поблагодарить Кузнецову Алину Александровну за добрые слова в те сложные минуты зубной боли, как психолог успокоила и направила к доктору. Персонал клиники весь профессиональный, лечение замечательное , стараются спасти каждый зуб, дают нужные советы. Особенно хочется выразит благодарность врачу ортопеду стоматологу Филимонцеву Никите Сергеевичу, за его профессионализм, как говорят есть врачи, а есть врачи от Бога , то это про Никиту Сергеевича. Спасибо всем сотрудникам и врачам стомотолооической клинике Дантис Мастер, Мастера своего дела с большой буквы. Всех благ и процветания клиники.",
  },
  {
    id: 2,
    star: 5,
    site: "yandex",
    link: "https://yandex.ru/web-maps/org/1085319176/reviews?reviews[publicId]=v7yh3xne0hfc3v8qqq19kk2gv4&utm_source=review",
    date: "19 мая 2023",
    name: "Марк Шадрин",
    text: "Выражаю огромную благодарность Касимовой Лилии Файзыровне и клинике в целом за клиентоориентированность, профессионализм, любовь к своей работе! Подход к детям это отдельная история. Я аплодирую стоя и одновременно низко кланяюсь! В иных местах говорили -«ничего не выйдет, или сделайте те анализы, эти анализы, то, сё пятое, десятное.. А тут в течении 5 мин после интересного вовлечения ребенка в процесс уже работала машинка. Короче говоря рекомендую! Зубки детей, свои и своих близких доверю не сомневаясь!",
  },
  {
    id: 3,
    star: 5,
    site: "yandex",
    link: "https://yandex.ru/web-maps/org/1085319176/reviews?reviews[publicId]=z6cxqytxu1bpwqr6cryxdde874&utm_source=review",
    date: "24 октября 2023",
    name: "Mirien Hiraoka",
    text: "Вежливое обращение персонала, в самой стоматологии чисто и приятно находиться. Лечили кариес в два приёма, врач сделала свою работу очень качественно, впечатления остались исключительно положительные.",
  },
  {
    id: 4,
    star: 5,
    site: "yandex",
    link: "https://yandex.ru/web-maps/org/1085319176/reviews?reviews[publicId]=5q56pqjud5g0ym3cd1ce8mfqn8&utm_source=review",
    date: "25 октября 2023",
    name: "Полина Т.",
    text: "Была очень сложная ситуация с зубами, все врачи до посещения этой клиники говорили, что зубы под удаление или протезирование, но ни то, ни другое не подходило, поскольку ношу брекеты, подходил только вариант всеми возможными и невозможными спососбами вылечить зубы. И только Мамомонтова Мария Алексеевна из клиники «Дантист Мастер» взялась зв решение моей проблемы, благодаря ее профессионализму все лечение прошло замечательно, спасла мои зубки и ничего не болит. Замечательный врач, огромная благодарность ей.",
  },
  {
    id: 5,
    star: 5,
    site: "yandex",
    link: "https://yandex.ru/web-maps/org/1085319176/reviews?reviews[publicId]=y5ehrrkzeahbgqzmrtn789119m&utm_source=review",
    date: "26 октября 2023",
    name: "Игорь Гнездилов",
    text: "Все прекрасно, врачи понимающие, всегда могу успокоить в момент страха. Спасибо больнице, перепробовал много больниц по лечению, это одна из лучших, не первый год здесь лечусь и вас советую!",
  },
  {
    id: 6,
    star: 5,
    site: "yandex",
    link: "https://yandex.ru/web-maps/org/1085319176/reviews?reviews[publicId]=xygt2gzakphpf43ndanmrf472m&utm_source=review",
    date: "26 октября 2023",
    name: "Полина Сентякова",
    text: "Выражаю благодарность Окуловой Татьяне Владимировне. Хожу к ней постоянно, всегда все качественно сделает, даст рекомендации, найдет выход из даже самой узкой ситуации. Желаю процветания клиники. И до новых встреч 😀",
  },
];

const swiperParams = {
  breakpoints: {
    320: {
      slidesPerView: 1,
      spaceBetween: 32,
    },
    768: {
      slidesPerView: 3,
      spaceBetween: 0,
    },
  },
};

const Reviews = () => {
  const sliderRef = useRef(null);
  const sliderBgRef = useRef(null);
  const [isShowModal, setIsShowModal] = useState(false);
  const [activeReviewId, setActiveReviewId] = useState(null);

  useEffect(() => {
    Object.assign(sliderRef.current, swiperParams);
    sliderRef.current.initialize();

    if (sliderRef && sliderBgRef) {
      const bgAnimationEl = sliderBgRef.current;
      const sliderEl = sliderRef.current;
      const slideEls = sliderEl.querySelectorAll(".slide");

      sliderEl.addEventListener("mouseover", (event) => {
        const slideActiveEl = event.target.closest(".slide");

        if (slideActiveEl) {
          if (slideEls) {
            [...slideEls].map((slide) => slide.classList.remove("active"));
          }

          slideActiveEl.classList.add("active");
          const slideRect = slideActiveEl.getBoundingClientRect();
          const x = slideRect.left - sliderEl.getBoundingClientRect().left;
          const y = slideRect.top - sliderEl.getBoundingClientRect().top;
          const width = slideRect.width;
          const height = slideRect.height;

          bgAnimationEl.classList.add("active");
          bgAnimationEl.style.width = width + "px";
          bgAnimationEl.style.height = height + "px";
          bgAnimationEl.style.transform =
            "translateX(" + x + "px ) translateY(" + y + "px) translateZ(0)";
        }
      });

      sliderEl.addEventListener("mouseleave", (event) => {
        bgAnimationEl.classList.remove("active");

        if (slideEls) {
          [...slideEls].map((slide) => slide.classList.remove("active"));
        }
      });
    }
  }, []);

  return (
    <Container>
      <Styled.Reviews>
        <Styled.ReviewsDecorative>
          <IconReviewsDecorative />
        </Styled.ReviewsDecorative>
        <Styled.ReviewsHeader>
          <Styled.ReviewsTitle>Отзывы</Styled.ReviewsTitle>
          <Styled.ReviewsHeaderDecorative />
          <Styled.ReviewsSliderArrows>
            <Styled.ReviewsSliderArrow
              onClick={() => sliderRef.current.swiper.slidePrev()}
              aria-label="Предыдущий"
            >
              <SvgIcon width="24" height="24" name="icon-arrow-left" />
            </Styled.ReviewsSliderArrow>
            <Styled.ReviewsSliderArrow
              onClick={() => sliderRef.current.swiper.slideNext()}
              aria-label="Следующий"
            >
              <SvgIcon width="24" height="24" name="icon-arrow-right" />
            </Styled.ReviewsSliderArrow>
          </Styled.ReviewsSliderArrows>
        </Styled.ReviewsHeader>
        <Styled.ReviewsContent>
          <Styled.ReviewsBg ref={sliderBgRef} />
          <swiper-container
            init="false"
            ref={sliderRef}
            slides-per-view="3"
            loop="true"
            autoplay-delay="5000"
            autoplay-disable-on-interaction="false"
            autoplay-pause-on-mouse-enter="true"
          >
            {reviewsData.map((review) => (
              <swiper-slide key={review.id}>
                <ReviewCard
                  id={review.id}
                  star={review.star}
                  site={review.site}
                  link={review.link}
                  date={review.date}
                  name={review.name}
                  text={review.text}
                  onOpenReview={setActiveReviewId}
                  onShowModal={setIsShowModal}
                />
              </swiper-slide>
            ))}
          </swiper-container>
        </Styled.ReviewsContent>
      </Styled.Reviews>
      <Modal
        isShow={isShowModal}
        onChangeShowModal={setIsShowModal}
        className="horizont-indent-lg"
      >
        {reviewsData.map((review) => {
          if (review.id === activeReviewId) {
            return (
              <Fragment key={review.id}>
                <ReviewModal
                  name={review.name}
                  text={review.text}
                  date={review.date}
                />
              </Fragment>
            );
          }
          return null;
        })}
      </Modal>
    </Container>
  );
};

export default Reviews;
