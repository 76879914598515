import * as Styled from "./styles";

const ToggleSwitch = ({ text, onChange, ...props }) => {
  const handlerChange = (e) => {
    onChange(e);
  };

  return (
    <Styled.ToggleSwitch>
      <Styled.ToggleSwitchCheck
        {...props}
        onChange={handlerChange}
        type="checkbox"
      />
      <Styled.ToggleSwitchField />
      <Styled.ToggleSwitchText>{text}</Styled.ToggleSwitchText>
    </Styled.ToggleSwitch>
  );
};

export default ToggleSwitch;
