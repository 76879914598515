import * as Styled from "./styles";
import SvgIcon from "../svg-icon/SvgIcon";

const Logo = ({ width = 70, height = 70 }) => {
  return (
    <Styled.Logo to="/">
      <Styled.LogoIcon>
        <SvgIcon name="icon-logo" width={width} height={height} />
      </Styled.LogoIcon>
      <Styled.LogoInfo>
        <Styled.LogoInfoText>Дантист Мастер</Styled.LogoInfoText>
        <Styled.LogoInfoDescr>Всегда для Вас</Styled.LogoInfoDescr>
      </Styled.LogoInfo>
    </Styled.Logo>
  );
};

export default Logo;
