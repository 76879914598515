import ServicesGrid from "../../blocks/services/services-grid/ServicesGrid";
// import ServicesPrice from "../../blocks/services/services-price/ServicesPrice";
import { Helmet } from "react-helmet";

const PageServices = () => {
  return (
    <>
      <Helmet>
        <title>Наши услуги</title>
        <meta
          name="description"
          content="Здесь перечисляются основные стоматологические услуги, предлагаемые клиникой."
        />
        <meta
          name="keywords"
          content="стоматология, стоматология ижевск, ижевск, телефон стоматологии, врачи стоматологии, стоматология цены, сайт стоматологии, ул. Союзная 107, зуб, имплантация, пародонтология, протезирование, рентген-диагностика, терапия, хирургия"
        />
        <meta property="og:title" content="Дантист Мастер - Наши услуги" />
      </Helmet>
      <ServicesGrid />
      {/* <ServicesPrice /> */}
    </>
  );
};

export default PageServices;
