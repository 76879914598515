export const postData = async (url, formData) => {
  const result = await fetch(url, {
    method: "POST",
    body: formData,
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
    },
  });

  return await result.json();
};
