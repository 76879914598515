import Layout from "../layout/Layout";
import PageAbout from "../pages/page-about/PageAbout";
import PageContacts from "../pages/page-contacts/PageContacts";
import PageMain from "../pages/page-main/PageMain";
import PagePrivacy from "../pages/page-privacy/PagePrivacy";
import PageServices from "../pages/page-services/PageServices";
import PageSpecialists from "../pages/page-specialists/PageSpecialists";
import SvgSprite from "../ui/svg-sprite/SvgSprite";
import { GlobalStyle } from "./styles";

import { BrowserRouter, Routes, Route } from "react-router-dom";

const App = () => {
  return (
    <>
      <GlobalStyle />
      <SvgSprite />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<PageMain />} />
            <Route path="about" element={<PageAbout />} />
            <Route path="specialists" element={<PageSpecialists />} />
            <Route path="privacy" element={<PagePrivacy />} />
            <Route path="services" element={<PageServices />} />
            <Route path="contacts" element={<PageContacts />} />
            <Route path="*" element={<>404</>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
