import styled, { css } from "styled-components";
import { device } from "../../../thems/breakpoint";

export const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  opacity: 0.8;
  pointer-events: none;
  z-index: 800;
`;

export const ModalDialog = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: auto;
  max-width: ${(props) => (props.$maxWidth ? props.$maxWidth + "px" : "600px")};
  min-height: calc(100% - 64px);
  margin: 32px auto;
  padding: 0 32px;
  pointer-events: none;
  z-index: 900;

  @media ${device.tablet} {
    padding: 0 48px;
  }
`;

export const ModalContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 15px;
  color: #1e252b;
  background-color: #fff;
  pointer-events: auto;
  outline: none;
`;

export const ModalHeader = styled.header`
  flex-shrink: 0;
`;

export const ModalBody = styled.div`
  position: relative;
  flex: 1 1 auto;
  padding: 16px;

  @media ${device.tablet} {
    padding: 32px;
  }
`;

export const ModalFooter = styled.footer`
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;
  padding: 0 16px 16px;

  @media ${device.tablet} {
    padding: 0 32px 32px;
  }
`;

export const ModalCross = styled.button`
  position: absolute;
  top: 0;
  right: -40px;
  display: none;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 0;
  border: 0;
  color: #fff;
  background-color: transparent;
  opacity: 0.6;
  cursor: pointer;
  transition: opacity 0.15s linear;

  &:hover {
    opacity: 1;
  }

  @media ${device.tablet} {
    display: flex;
  }
`;

export const Modal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: none;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1000;

  ${({ $isShow }) =>
    $isShow &&
    css`
      display: block;
    `}

  &.horizont-indent-lg {
    ${ModalDialog} {
      min-height: calc(100% - 100px);
      margin: 50px auto;
    }
  }
`;
