import styled from "styled-components";
import { device } from "../../../thems/breakpoint";

export const Container = styled.div`
  max-width: ${(props) => props.theme.widthContent};
  margin: 0 auto;
  padding: 0 16px;

  @media ${device.mobileL} {
    padding: 0 32px;
  }
`;
