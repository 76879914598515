import styled from "styled-components";

export const ToggleSwitchField = styled.span`
  position: relative;
  display: block;
  width: 50px;
  height: 28px;
  background-color: ${(props) => props.theme.switchBg};
  border-radius: 100px;

  &:active::after {
    aspect-ratio: 1.5 / 1;
  }

  &::after {
    position: absolute;
    top: 5px;
    left: 5px;
    bottom: 5px;
    aspect-ratio: 1 / 1;
    background: #fff;
    border-radius: 50%;
    transition: 0.3s linear;
    content: "";
  }
`;

export const ToggleSwitchText = styled.p`
  margin: 0;
  margin-left: 12px;
  font-size: 16px;
  font-weight: 400;
  color: ${(props) => props.theme.textColorOpacity60};
  transition: color 0.15s linear, background-color 0.15s linear;
`;

export const ToggleSwitchCheck = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;

  &:checked + ${ToggleSwitchField} {
    &::after {
      left: calc(100% - 5px);
      transform: translateX(-100%);
      background-color: rgb(234, 97, 101);
    }
  }

  &:checked ~ ${ToggleSwitchText} {
    color: #fff;
  }
`;

export const ToggleSwitch = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    ${ToggleSwitchText} {
      color: ${(props) => props.theme.textColorOpacity90};
    }
  }
`;
