import styled from "styled-components";
import { device } from "../../../thems/breakpoint";

export const Reviews = styled.section`
  position: relative;
  margin-top: 180px;
  padding-bottom: 60px;

  @media ${device.mobileL} {
    padding-bottom: 120px;
  }
`;

export const ReviewsDecorative = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  pointer-events: none;

  svg {
    width: 100%;
    height: 100%;
    opacity: 0.4;
  }
`;

export const ReviewsHeader = styled.header`
  display: flex;
  align-items: center;
  gap: 32px;
`;

export const ReviewsTitle = styled.h2`
  margin: 0;
  font-size: 48px;
  font-weight: 600;
`;

export const ReviewsHeaderDecorative = styled.div`
  width: 100%;
  max-width: 200px;
  height: 2px;
  background-color: #ea6165;
`;

export const ReviewsContent = styled.div`
  position: relative;
  margin-top: 32px;
`;

export const ReviewsBg = styled.div`
  position: absolute;
  top: 30px;
  left: -30px;
  display: none;
  background-color: #fff;
  transition: all 0.3s;
  transform: translateX(0) translateY(0) translateZ(0);
  opacity: 0;
  pointer-events: none;
  border-radius: 15px;

  &.active {
    top: 0;
    left: 0;
    opacity: 1;
  }

  @media ${device.tablet} {
    display: block;
  }
`;

export const ReviewsSliderArrows = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  gap: 12px;
  margin-left: auto;

  @media ${device.mobileL} {
    position: static;
    gap: 16px;
  }
`;

export const ReviewsSliderArrow = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: auto;
  height: 44px;
  margin: 0;
  padding: 0;
  border: 1.5px solid rgba(255, 255, 255, 0.6);
  border-radius: 12px;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.15s linear, color 0.15s linear,
    border-color 0.15s linear;

  &:hover {
    border-color: #fff;
    color: #1e252b;
    background-color: #fff;
  }

  @media ${device.mobileL} {
    width: 44px;
    border: 0;
    border-radius: 50%;
    color: #1e252b;
    background-color: #fff;

    &:hover {
      color: #fff;
      background-color: #ea6165;
    }
  }
`;
