import styled from "styled-components";
import { device } from "../../../thems/breakpoint";

export const Title = styled.h2`
  margin: 0;
  font-size: 32px;
  line-height: 1.2;
  font-weight: 600;
  color: ${(props) => props.theme.textColorOpacity90};

  @media ${device.mobileL} {
    font-size: 40px;
  }

  @media ${device.tablet} {
    font-size: 48px;
  }
`;
