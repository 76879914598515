import styled from "styled-components";

export const SpecialistsModalName = styled.h4`
  margin: 0;
  font-size: 24px;
`;

export const SpecialistsModalPosition = styled.p`
  margin: 0;
  margin-top: 4px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  text-transform: uppercase;
`;

export const SpecialistsModalEducations = styled.div`
  margin-top: 32px;
`;

export const SpecialistsModalEducationsList = styled.ul``;

export const SpecialistsModalEducationsText = styled.p`
  position: relative;
  margin: 0;
  padding-left: 30px;

  &::before {
    position: absolute;
    top: 0;
    left: 4px;
    bottom: 0;
    width: 2px;
    background-color: ${(props) => props.theme.accentColor};
    content: "";
  }

  &::after {
    position: absolute;
    left: 0;
    top: 7px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.accentColor};
    content: "";
  }
`;

export const SpecialistsModalEducationsItem = styled.li`
  &:not(:last-child) {
    ${SpecialistsModalEducationsText} {
      padding-bottom: 16px;
    }
  }

  &:first-child {
    ${SpecialistsModalEducationsText} {
      &::before {
        top: 7px;
      }
    }
  }

  &:last-child {
    ${SpecialistsModalEducationsText} {
      &::before {
        top: -7px;
        height: 20px;
        bottom: auto;
      }
    }
  }
`;
