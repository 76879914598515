import styled from "styled-components";
import { device } from "../../../../thems/breakpoint";

export const SpecialistsGrid = styled.div`
  margin-top: 120px;
`;

export const SpecialistsGridTitle = styled.h2`
  margin: 0;
  margin-bottom: 42px;
  font-size: 32px;
  font-weight: 600;

  @media ${device.mobileL} {
    font-size: 48px;
  }
`;

export const SpecialistsGridList = styled.div`
  > * + * {
    margin-top: 32px;
  }
  @media ${device.tablet} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;

    > * + * {
      margin-top: 0;
    }
  }
`;
