import { useEffect, useState } from "react";
import * as Styled from "./styles";

const Input = ({
  selector = "input",
  placeholder = "",
  onChange,
  value,
  ...props
}) => {
  const [valueField, setValueField] = useState(value);

  const handlerChange = (e) => {
    setValueField(e.target.value.trim());
    onChange(e);
  };

  useEffect(() => {
    setValueField(value);
  }, [value]);

  return (
    <Styled.InputBox className={!!valueField && "filled"}>
      <Styled.InputField
        {...props}
        onChange={handlerChange}
        as={selector}
        value={valueField}
      />
      <Styled.InputText>
        {placeholder}
        {props.required ? (
          <Styled.InputTextRequired>*</Styled.InputTextRequired>
        ) : (
          ""
        )}
      </Styled.InputText>
    </Styled.InputBox>
  );
};

export default Input;
