export const getInitialsUser = (name) => {
  return name.split(" ").reduce((acc, item) => {
    return acc + item[0];
  }, "");
};

export const getCropComment = (text) => {
  if (text.length > 100) {
    return text.slice(0, 300) + "...";
  }
  return text;
};
