import { NavLink, useLocation } from "react-router-dom";
import { useClickAway } from "@uidotdev/usehooks";
import Container from "../container/Container";
import * as Styled from "./styles";
import Logo from "../../ui/logo/Logo";
import { useEffect, useState } from "react";
import NavMobile from "./nav/mobile/NavMobile";
import { navLinkList } from "../../../data/navLinkList";

const Header = () => {
  const [isOpenNavMobile, setIsOpenNavMobile] = useState(false);
  const refNavMobile = useClickAway(() => {
    setIsOpenNavMobile(false);
  });
  const { pathname } = useLocation();

  const handlerShowMenuMobile = () => {
    setIsOpenNavMobile((prev) => !prev);
  };

  useEffect(() => {
    const bodyEl = document.body;

    if (isOpenNavMobile) {
      bodyEl.classList.add("show-menu");
    } else {
      bodyEl.classList.remove("show-menu");
    }
  }, [isOpenNavMobile]);

  useEffect(() => {
    setIsOpenNavMobile(false);
  }, [pathname]);

  return (
    <Styled.Header>
      <Container>
        <Styled.HeaderContent>
          <Logo />
          <Styled.HeaderNav>
            {navLinkList.map((link, index) => (
              <NavLink
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "active" : ""
                }
                to={link.href}
                key={index}
              >
                {link.name}
              </NavLink>
            ))}
          </Styled.HeaderNav>
          <Styled.HeaderButton link="#feedback">
            Обратный звонок
          </Styled.HeaderButton>
          <Styled.HeaderMenuIcon
            className={isOpenNavMobile ? "open" : ""}
            onClick={handlerShowMenuMobile}
            aria-label={isOpenNavMobile ? "Закрыть меню" : "Показать меню"}
          >
            <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
              <path
                className="line--1"
                d="M0 40h62c13 0 6 28-4 18L35 35"
              ></path>
              <path className="line--2" d="M0 50h70"></path>
              <path
                className="line--3"
                d="M0 60h62c13 0 6-28-4-18L35 65"
              ></path>
            </svg>
          </Styled.HeaderMenuIcon>
          <NavMobile ref={refNavMobile} navLinkList={navLinkList} />
        </Styled.HeaderContent>
      </Container>
    </Styled.Header>
  );
};

export default Header;
