import styled from "styled-components";
import { device } from "../../../thems/breakpoint";

export const Hero = styled.section`
  display: flex;
  align-items: center;
  min-height: 400px;
`;

export const HeroContent = styled.div``;

export const HeroTitle = styled.h1`
  margin: 0;
  font-size: 42px;
  text-transform: uppercase;

  @media ${device.tablet} {
    font-size: 80px;
  }
`;

export const HeroText = styled.p`
  margin: 0;
  margin-top: 12px;
  font-size: 24px;
  color: ${(props) => props.theme.textAccent};

  @media ${device.tablet} {
    font-size: 32px;
  }
`;
