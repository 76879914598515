import styled from "styled-components";

export const ReviewModal = styled.div`
  padding: 36px 0 0;
  text-align: center;
`;

export const ReviewModalImage = styled.div`
  position: absolute;
  top: -35px;
  left: 50%;
  transform: translateX(-50%);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  font-weight: 400;
  color: #fff;
  background-color: #ea6165;
`;

export const ReviewModalName = styled.p`
  margin: 0;
  font-size: 24px;
  line-height: 1;
  font-weight: 600;
`;

export const ReviewModalDate = styled.p`
  margin: 0;
  margin-top: 8px;
  font-size: 16px;
  opacity: 0.6;
`;

export const ReviewModalText = styled.p`
  margin: 0;
  margin-top: 16px;
`;
