import styled from "styled-components";
import { device } from "../../../../thems/breakpoint";

export const ServiceCard = styled.div`
  position: relative;
  height: 100%;
  padding: 32px 16px;
  padding-top: 70px;
  border-radius: 15px;
  background: ${(props) => props.theme.serviceCardBg};
  transition: transform 0.15s linear;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    opacity: 0;
    background: ${(props) => props.theme.serviceCardBgHover};
    transition: opacity 0.15s linear;
    content: "";
    z-index: -1;
  }

  &:hover {
    transform: translateY(-20px);

    &::before {
      opacity: 1;
    }
  }

  @media ${device.mobileM} {
    padding: 32px;
    padding-top: 70px;
  }
`;

export const ServiceCardLogo = styled.div`
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.bgColor};

  svg {
    width: 100px;
    height: 100px;
  }

  @media ${device.mobileM} {
  }
`;

export const ServiceCardName = styled.h3`
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
`;

export const ServiceCardText = styled.p`
  margin: 0;
  margin-top: 30px;
  font-size: 18px;
  color: ${(props) => props.theme.textColorOpacity60};
  text-align: center;
`;
