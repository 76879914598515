// import { useOutletContext } from "react-router-dom";
import Button from "../../../ui/button/Button";
import * as Styled from "./styles";

const SpecialistCard = ({
  id,
  name,
  position,
  education = null,
  image,
  className,
  onShowModal,
  onShowSpecialist,
}) => {
  // const [setSpecialistFeedbackId] = useOutletContext();

  const showEducationHandler = () => {
    onShowSpecialist(id);
    onShowModal(true);
  };

  return (
    <Styled.SpecialistCard className={className}>
      <Styled.SpecialistCardImage className={className}>
        <img src={image.webp} alt={`${name} - ${position}`} />
      </Styled.SpecialistCardImage>
      <Styled.SpecialistCardInfo className={className}>
        <Styled.SpecialistCardName className={className}>
          {name}
        </Styled.SpecialistCardName>
        <Styled.SpecialistCardPosition className={className}>
          {position}
        </Styled.SpecialistCardPosition>
        <Styled.SpecialistCardButtons className={className}>
          {education && (
            <Button
              className="outline"
              svgName="icon-education"
              onClick={showEducationHandler}
            >
              Образование
            </Button>
          )}

          <Button className="outline" svgName="icon-phone" link="#feedback">
            Записаться
          </Button>
        </Styled.SpecialistCardButtons>
      </Styled.SpecialistCardInfo>
    </Styled.SpecialistCard>
  );
};

export default SpecialistCard;
