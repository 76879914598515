import styled from "styled-components";
// import { device } from "../../../thems/breakpoint";

export const Contacts = styled.section`
  margin-top: 100px;
`;

export const ContactsTitle = styled.h2`
  margin: 0;
  font-size: 48px;
  font-weight: 600;
  text-align: center;
`;

export const ContactsList = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
`;

export const ContactsListItem = styled.li``;

export const ContactHeader = styled.header`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const ContactHeaderIcon = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  border: 2px solid transparent;
  border-radius: 8px;
  background-clip: padding-box;
  background-color: #1e252b;
  color: rgb(234, 97, 101);

  &::before {
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    border-radius: inherit;
    background: rgb(234, 97, 101);
    background: linear-gradient(
      135deg,
      rgba(234, 97, 101, 0) 0%,
      rgba(234, 97, 101, 1) 100%
    );
    z-index: -1;
    content: "";
  }
`;

export const ContactHeaderTitle = styled.h3`
  margin: 0;
  color: rgb(234, 97, 101);
`;

export const ContactInfo = styled.div``;

export const ContactButtons = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
`;

export const ContactButton = styled.a``;

export const Contact = styled.div`
  height: 100%;
  padding: 32px;
  border-radius: 15px;
`;
