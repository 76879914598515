import Reviews from "../../blocks/reviews/Reviews";
import ServicesGrid from "../../blocks/services/services-grid/ServicesGrid";
import SpecialistsSlider from "../../blocks/specialists/specialists-slider/SpecialistsSlider";
import * as Styled from "./styles";
import { Helmet } from "react-helmet";

const Main = () => {
  return (
    <Styled.Main>
      <Helmet>
        <title>Дантист Мастер - Стоматология Ижевск</title>
        <meta
          name="description"
          content="Стоматологические услуги в Ижевске. Имплантация, хирургия, протезирование, терапия, парадонтология. Позвоните нам сейчас! +7 (3412) 37-11-31"
        />
        <meta
          name="keywords"
          content="стоматология, стоматология ижевск, ижевск, телефон стоматологии, врачи стоматологии, стоматология цены, сайт стоматологии, ул. Союзная 107, зуб, имплантация, пародонтология, протезирование, рентген-диагностика, терапия, хирургия"
        />
        <meta
          property="og:title"
          content="Дантист Мастер - Стоматология Ижевск"
        />
      </Helmet>
      <ServicesGrid />
      <SpecialistsSlider />
      <Reviews />
    </Styled.Main>
  );
};

export default Main;
