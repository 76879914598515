import ServiceCard from "../service-card/ServiceCard";
import * as Styled from "./styles";

import { ReactComponent as IconServicesDecorative } from "../icon-services-decor.svg";
import Container from "../../../layout/container/Container";
import { servicesData } from "../../../../mock/services";

const ServicesGrid = () => {
  return (
    <Styled.ServicesGrid>
      <Styled.ServicesGridDecorative>
        <IconServicesDecorative />
      </Styled.ServicesGridDecorative>
      <Container>
        <Styled.ServicesGridTitle>Услуги</Styled.ServicesGridTitle>
        <Styled.ServicesGridList>
          {servicesData.map((service) => {
            if (service.visible) {
              return (
                <Styled.ServicesGridListItem key={service.id}>
                  <ServiceCard
                    name={service.name}
                    text={service.text}
                    logo={service.logo}
                  />
                </Styled.ServicesGridListItem>
              );
            }

            return null;
          })}
        </Styled.ServicesGridList>
      </Container>
    </Styled.ServicesGrid>
  );
};

export default ServicesGrid;
